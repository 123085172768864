import React from 'react';

import JoinEarlyButton from './JoinEarlyButton';
import JoinNowButton from './JoinNowButton';
import SeeInCanvasButton from './SeeInCanvasButton';

export default function EachEventButton({
    eventState=null,
    eventItem=null
}) {

    return (
        <div>
            {
                eventState && eventState==="is_live"?
                <>
                    {
                        eventItem?.meeting_url?
                        <a href={eventItem.meeting_url} target="_blank" rel="noreferrer">
                            <JoinNowButton 
                                eventState={eventState} />
                        </a>:
                         <JoinNowButton 
                            eventState={eventState} 
                            noLink={true} />
                    }
                </>
                    :
                eventState && eventState==="is_passed"?
                //    <RecordingButton 
                //         eventState={eventState}
                //         eventItem={eventItem}
                //    />
                <></>
                   :
                eventState && eventState==="is_join_early"?
                <>
                    {
                        eventItem?.meeting_url?
                        <a href={eventItem.meeting_url} target="_blank" rel="noreferrer">
                            <JoinEarlyButton 
                                eventState={eventState}
                            />
                        </a>:
                        <JoinEarlyButton 
                            eventState={eventState} 
                            noLink={true} />
                    }
                </>:
                eventState && eventState === "no_zoom"?
                <>
                    {
                        eventItem?.event_url ?
                        <a href={eventItem.event_url} target="_blank" rel="noreferrer">
                            <SeeInCanvasButton/>
                        </a>:
                        null
                    }
                </>:
                null
            }
        </div>
    )
}