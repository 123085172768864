import { makeStyles } from "@mui/styles"

export const eventTypeBgColorMapping={
    "class":"#F9F9F9",
    "event":"#F1FFF3",
    'support':"#FAF5FF"
}

export const eventTypeBorderColorMapping={
    "class":"#E6E6E6",
    "event":"#66C375",
    'support':"#8F56D7"
}

export const eventTypeColorMapping={
    "class":"#1D3969",
    "event":"#14606B",
    'support':"#8F56D7"
}

export const useStyles = makeStyles(theme => ({

    skeletonLoaderContainer:{
        padding: '15px 10px',
        display: "flex",
        justifyContent:"space-between",
        alignItems:"center",
        [theme.breakpoints.down("sm")]: {
            flexDirection:"column"
        }
    },

    skeletonButton: {
        borderRadius: "34px !important",
        height: "34px !important",
        width: "128px !important",
        [theme.breakpoints.down("sm")]: {
            width: "300px !important",
            marginTop: "10px",
        }
    },

    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: '0px 10px 10px 10px',
            alignItems: "center"
        },
    },
    outterTabContainer: {
        backgroundColor: "#E4EEFF",
        borderRadius: "8px 8px 0px 0px"
    },

    /** Event Panel Tabs */
    tabContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        alignItems:"center",
        alignContent:"center",
        '&:hover': {
            cursor: "pointer"
        },
        '& .tab-selected':{
            backgroundColor: "white !important"
        },
        '& .tab-disabled':{
            opacity:"0.8",
            cursor:"not-allowed",
            color:"rgb(0, 0, 0, 0.12)"
        }
    },
    tabDisabled: {
        color: "rgba(0, 0, 0, 0.3) !important"
    },
    tabContentContainer:{
        padding:"25px 0px 1px 0px",
        backgroundColor: "#FFFFFF",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        '& .event-grouped-date-container':{
            marginBottom:"15px",
            transition: 'all .5s ease-in-out',
            [theme.breakpoints.down('sm')]: {
                padding: "0px 25px"
            },

            '& .event-grouped-date-container-header':{
                padding:"0px 25px",
                [theme.breakpoints.down("sm")]: {
                    padding:"0px 10px",
                },
                display:"flex",
                justifyContent:"space-between",
                alignContent:"center",
                marginBottom:"15px"
            }
        }
    },
    eachTab: {
        width:"100%",
        backgroundColor: "transparent",
        textAlign:"center",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        fontFamily: "Nunito",
        color: theme.colors.deepBlue,
        height: "47px",
        '&#recent-tab': {
            borderTopLeftRadius: "8px"
        },
        '&#upcoming-tab': {
            borderTopRightRadius: "8px"
        },
        '&#today-tab': {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px"
        }
    },


    /** date header */
    dateHeader:{
        color:theme.colors.deepBlue,
        fontWeight:600
    },

    /** Each Event */
    eachEventOuterContainer:{
        position:"relative",
        padding:"0px 25px",
        [theme.breakpoints.down("sm")]: {
            padding:"0px 10px",
        },
        '& .happening-now-indicator':{
            [theme.breakpoints.down("sm")]: {
                display:"none"
            },
            width: "17px",
            height: "15px",
            backgroundColor: theme.colors.rust,
            position: "absolute",
            left:"0px",
            borderTopRightRadius:"10px",
            borderBottomRightRadius:"10px"
        }
    },

    eachEventContainer:{
        borderRadius:"10px",
        padding:"10px 15px",
        margin:"15px 0px",

        '&:first-child':{
            marginTop:"8px !important",
        },

        '& .each-event-inner-container':{

            [theme.breakpoints.up("sm")]: {
                display:"flex",
                justifyContent:"flex-start",
                alignItems:"center",
            },

            [theme.breakpoints.down("sm")]: {
                display:"block",
                alignItems:"center",
            },

            '& .each-event-details':{
                [theme.breakpoints.up("sm")]: {
                    flexGrow:2
                },
                '& .event-summary':{
                    fontSize:"14px",
                    margin: "15px 10px 10px 10px",
                    marginBottom:"15px",
                    fontWeight:600,
                    fontFamily:"Raleway !important"
                },
                '& .event-link:link':{
                    color: 'inherit'
                },
                '& .event-link:visited':{
                    color: 'inherit'
                },
                '& .event-link:hover':{
                    color: 'inherit'
                },
                '& .event-calendar-name':{
                    fontSize:"12px",
                    fontWeight:500,
                    lineHeight:"16px",
                    color:theme.colors.slateGrey
                },

                '& .event-timestamp':{
                    fontSize:"12px",
                    fontWeight:500,
                    margin:"0px 10px 10px 10px",
                    color:theme.colors.slateGrey
                }
            },
            
        }
    },

    /**
     * Boders per event type
     */
     eventBorder:{
        border:`1.5px solid ${eventTypeBorderColorMapping.event}`
    },

    classBorder:{
        border:`1.5px solid ${eventTypeBorderColorMapping.class}`
    },

    /**
     * Background Color per event type
     */
     eventBgColor:{
        backgroundColor:eventTypeBgColorMapping.event
    },

    classBgColor:{
        backgroundColor:eventTypeBgColorMapping.class
    },

    /**
     * Color per event type
     */
    eventColor:{
        color:eventTypeColorMapping.event
    },

    classColor:{
        color:eventTypeColorMapping.class
    },

    /** Each Event Header ITems */
    eventHeaderContainer:{
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center",
        fontSize:"12px",
        '& .event-type':{
            padding:"2px",
            color:'#404040',
            borderRadius:"4px",
            fontFamily:"Raleway",
            backgroundColor:"white",
            width:"62px",
            textAlign:"center",
            fontWeight:600
        },
        '& .event-state':{
            marginLeft:"8px",
            fontWeight: 600,
            '& [data-eventstate="is_passed"]':{
                color:theme.colors.deepAqua
            },
            '& [data-eventstate="is_live"]':{
                color:theme.colors.rust
            },
            '& [data-eventstate="is_join_early"]':{
                color:theme.colors.rust
            },
        },
        
    },

    /** No Events */
    noEventsSchedules:{
        padding:"0px 25px",
        [theme.breakpoints.down("sm")]: {
            padding:"0px 10px",
        },
        margin:"10px 0px",
        borderRadius:"10px",
        fontSize:"14px",
        color:'#414042'
    },

    /** Event Buttons */
    eventButton:{
        borderRadius: "34px !important",
        display:"flex",
        justifyContent:"center",
        alignContent:"center",
        alignItems:"center",
        height: "35px !important",
        fontSize:"12px",
        fontWeight:700,
        padding:"0px 10px",
        cursor:"pointer",
        [theme.breakpoints.up("sm")]: {
            minWidth: "128px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        '& .button-title':{
            marginLeft:"5px"
        }
    },

    recordingButton:{
        backgroundColor:theme.colors.deepAqua,
        color:"white",
        border:"1px solid "+theme.colors.deepAqua,
        '&:disabled':{
            opacity:0.5,
            cursor:"not-allowed",
        }
    },

    joinEarlyButton:{
        backgroundColor:'#FFFFFF',
        border:`1.5px solid ${theme.colors.rust}`,
        color:theme.colors.rust
    },

    joinNowButton:{
        border:"1px solid "+theme.colors.rust,
        backgroundColor:theme.colors.rust,
        color:'#FFFFFF',
        '&:disabled':{
            opacity:0.5,
            cursor:"not-allowed",
        }
    },
    
    /** Alignment */
    expandCollapseAllButton:{
        marginBottom:"15px",
        display:"flex",
        marginRight:"20px",
        justifyContent:"flex-end"
    },

    textButton:{
        ...theme.elements.subtitle,
        color:`${theme.colors.deepBlue} !important`,
        textTransform:"capitalize !important"
    },



    selectorWrap: {
        display: "flex",
        margin: "0px -23px"
    },
    selector: {
        ...theme.elements.subtitle,
        width: "373.5px",
        height: "47px",
        lineHeight: '47px',
        textAlign: 'center',
        color: theme.MyKenzie.actionColors.blueButton,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    selected: {
        background: theme.colors.white,
        border: "0px solid",
        borderRadius: "4px 4px 0px 0px"
    },
    selectorDisabled: {
        color: "rgba(0, 0, 0, 0.12)"
    },

    /**
     * No events today view
     */
    noEventsTodayContainer: {
        padding: "0px 25px"
    },
    noEventsTodayContent: {
        display: 'flex',
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "20px"
        }
    },
    noEventsTodayContext: { 
        [theme.breakpoints.down(950)]: {
            textAlign: "center"
        }
    },
    noEventsText: {
        color: theme.colors.deepBlue,
        marginBottom: "30px",
        fontSize: "18px"
    },
    noEventsSubtext: {
        fontSize: "14px",
        paddingRight: "5px"
    }
}))
