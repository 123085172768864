import { makeStyles } from "@mui/styles"
import React, { useRef } from "react"
import HtmlMapper from "react-html-map"
import { Link } from "react-router-dom"
import { useIsOverflow } from "../../acp/hooks/useIsOverflow"
const useStyles = makeStyles((theme) => ({
    body: {

        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: "#545454",
        margin: 0,
        position: 'relative',
        "& .ql-syntax": {
            backgroundColor: "#23241f",
            color: "#f8f8f2",
            overflow: "visible",
            paddingLeft: "8px",
            borderRadius: 3
        },
        "& .ql-indent-1": {
            paddingLeft: "3em"
        },
        "& .ql-indent-2": {
            paddingLeft: "6em"
        },
        "& .ql-indent-3": {
            paddingLeft: "9em"
        },
        "& .ql-indent-4": {
            paddingLeft: "12em"
        },
        "& blockquote": {
            borderLeft: "4px solid #ccc",
            paddingLeft: "16px"
        },
        '& .overflow-hider': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: '100%',
            width: '100%',

        },
        [theme.breakpoints.down('sm')]: {
            height: "52px"
        },
        overflowY: "hidden",
        textOverflow: 'ellipsis',
    }
}))

export default function HtmlParser(props) {
    const classes = useStyles()
    const clearStyle = (elemProps) => ({ ...elemProps, style: {} })
    const containerRef = useRef(null)
    const containerIsOverflowing = useIsOverflow(containerRef)
    // const [isPreview, setIsPreview] = useState(true)
    // useEffect(() => {
    //     setIsPreview(window.location.pathname === '/acp/listRequests/')
    // }, [window.location.pathname])

    return (
        <div className={classes.body} ref={containerRef} id="announcement" style={{ height: (props.isAnnouncement && props.readOption !== "Read Less") ? '53px' : (props.readOption === "Read Less" || props.announcementHistoryList) ? "auto" : props.isPreview ? "70px" : "auto" }}>
            {props.isPreview && containerIsOverflowing ?
                <div className="overflow-hider"
                    style={{

                        background: props.bgColor ? `linear-gradient(0deg, ${props.bgColor}  35%, rgba(0,0,0,0) 100%)` : `linear-gradient(90deg, rgba(255,255,255,1)  20%, rgba(0,0,0,0) 100%)`
                    }} />


                : null}
            <HtmlMapper html={props.children} className={'truncate-overflow'}>
                {{
                    p: (props) => <p {...clearStyle(props)} />,
                    h1: (props) => <h1 {...clearStyle(props)} />, //eslint-disable-line jsx-a11y/heading-has-content
                    h2: (props) => <h2 {...clearStyle(props)} />, //eslint-disable-line jsx-a11y/heading-has-content
                    strong: (props) => <strong {...clearStyle(props)} />,
                    u: (props) => <u {...clearStyle(props)} />,
                    em: (props) => <em {...clearStyle(props)} />,
                    s: (props) => <s {...clearStyle(props)} />,
                    blockquote: (props) => <blockquote {...clearStyle(props)} />,
                    pre: (props) => <pre {...clearStyle(props)} />,
                    ul: (props) => <ul {...clearStyle(props)} />,
                    ol: (props) => <ol {...clearStyle(props)} />,
                    li: (props) => <li {...clearStyle(props)} />,
                    span: (props) => <span {...clearStyle(props)} />,
                    a: ({ href, children, ...rest }) =>
                        href?.startsWith("/") ? (
                            // assume internal linkß
                            <Link to={href} {...clearStyle(rest)} style={{ wordBreak: 'break-word' }}>{children}</Link>
                        ) : (
                            <a style={{ wordBreak: 'break-word', }} href={href.startsWith("http") ? href : "//" + href} {...clearStyle(rest)}>
                                {children}
                            </a>
                        ),
                }}
            </HtmlMapper>
        </div>
    )
}