import * as React from "react"
import { makeStyles } from "@mui/styles"
import { Link, useLocation } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuid4 } from 'uuid';

import { setCoachSwitchToACPStorage } from "../../../helpers/userRolesHelper";
import { AppDataContext } from "../../../context";
import { LearnersDataContext } from "../../context/LearnersDataContext";

const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
    },
    item: {
        borderRadius: "4px",
        // float: "right",
        fontFamily: 'Raleway',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '33px',
        color: "#1D3969",
    },
    active: {
        backgroundColor: "#E0FBFF"
    },
    label: {
        margin: "10px 0px 10px 19px"
    }
}));



const MobileTabs = (props) => {
    const classes = useStyles()

    const { user } = useAuth0();
    const { userRole } = React.useContext(AppDataContext);
    const { disableWatchPage } = React.useContext(LearnersDataContext)
    const mobileTabs =  disableWatchPage ?
    [
        {
            label: "Home",
            path: "/"
        },
        {
            label: "Connect",
            path: "/connect"
        },

        {
            label: "My Profile",
            path: "/profile/" + user.email
        },

        {
            label: "Switch to ACP",
            path: "/acp/"
        }
    ]:
     [
        {
            label: "Home",
            path: "/"
        },
        {
            label: "Connect",
            path: "/connect"
        },
        {
            label: "Watch",
            path: "/watch"
        },
        {
            label: "My Profile",
            path: "/profile/" + user.email
        },

        {
            label: "Switch to ACP",
            path: "/acp/"
        }
    ]

    return (
        <div className={classes.wrapper}>
            {
                mobileTabs && mobileTabs.length > 0 ?
                    <>
                        {
                            mobileTabs.map(item => {

                                let onClickHandler = () => {
                                    props.setDrawerIsOpen(false)
                                }

                                //If MenuItem is Swicth to ACP. Check if user is Coach. 
                                //If not, DONT show Switch to ACP Menu Item
                                if (item.label === "Switch to ACP" && userRole.id === 5) {
                                    onClickHandler = () => {
                                        setCoachSwitchToACPStorage();
                                        window.location.assign('/');//re-routes
                                        props.setDrawerIsOpen(false);
                                    }
                                } else if (item.label === "Switch to ACP" && userRole.id !== 5) {
                                    return null;//return ;
                                }

                                return <LinkItems
                                    key={uuid4()}
                                    item={item}
                                    onClickHandler={onClickHandler} />
                            })

                        }
                    </> :
                    null
            }
        </div>
    )
}

const LinkItems = ({ item, onClickHandler }) => {

    const location = useLocation();
    const classes = useStyles()

    const [activeTab, setActiveTab] = React.useState(null);

    React.useEffect(() => {
        setActiveTab(location.pathname)
    }, [activeTab, location]);

    return <Link style={{ textDecoration: "none" }}
        className={`${classes.item} ${activeTab === item.path ? classes.active : ""}`}
        to={item.path}
        onClick={() => onClickHandler()}>
        <p className={classes.label}>{item.label}</p>
    </Link>
}

export default MobileTabs