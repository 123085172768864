import React from 'react';
import { useStyles } from './styles';

export default function SeeInCanvasButton({
    eventState = null,
}) {

    const classes = useStyles();

    return (  
        <button data-eventstate={eventState}
            className={`${classes.joinNowButton} ${classes.eventButton}`}
            title={"Click to see in canvas"}
        >
            <div style={{ marginLeft: "5px" }}>See In Canvas</div>
        </button>
    )
}