import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer, IconButton, Menu, MenuItem, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/styles";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import { AppDataContext } from "../../../context";

import { ReactComponent as MkLogo } from '../../../images/my-kenzie-logo.svg';
import LinkTab from "../../../sharedComponents/LinkTab";

import { setCoachSwitchToACPStorage } from "../../../helpers/userRolesHelper";
import "./headerAnimation.css";
import useHeaderStyles from "./headerStyle";
import MobileLearnerInfo from "./MobileLearnerInfo";
import MobileTabs from "./MobileTabs";
import UserAvatar from "./UserAvatar";
import { ReactComponent as HomeIcon } from '../../../icons/home_blue.svg'
import { ReactComponent as WatchIcon } from '../../../icons/watch_blue.svg'
import { ReactComponent as ConnectIcon } from '../../../icons/connect_blue.svg'

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />

))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: 'transparent',
        height: "3px",
        marginLeft: "-10px"
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        borderRadius: "20px",
        backgroundColor: '#63C29D',
    },
});

export default function Header(props) {

    const classes = useHeaderStyles();

    const { user } = useAuth0();
    const location = useLocation()
    const [nav, setNav] = React.useState(false)

    const { userRole, userData } = React.useContext(AppDataContext);
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(false)

    const theme = useTheme();
    //Checks if the screen size is small or mobile screen and renders the menu accordingly 
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
    * handleLogout will update the user's online status (the 'is_online' column in Db) to false
    */
    const handleLogout = async () => {
        window.dispatchEvent(window.logoutEvent);
    }


    React.useEffect(() => {
        if (location.pathname) {
            if (location.pathname === "/") {
                setNav(0);
            } else if (location.pathname === "/connect") {
                setNav(1);
            }
            // else if (location.pathname === '/watch') {
            //     setNav(2)
            // } 
            else {
                setNav(false);
            }
        }
    }, [location]);

    return (
        <>
            <div className={classes.parentWrapper}>
                <div className={classes.header}>
                    <div className={classes.headerContent}>
                        <div className={classes.userDetails}>
                            <div className={classes.image}>
                                <MkLogo />
                            </div>
                            {!isSmallScreen &&
                                <StyledTabs className={classes.topNavTabs} name="Nav"
                                    value={nav} aria-label="learners dashboard top menu">
                                    <div className={classes.navIcon} id="home-nav-icon">
                                        <HomeIcon />
                                    </div>
                                    <LinkTab value={0} className={`${classes.topNavLabel} ${nav === 0 ? classes.selectedNavLabel : null}`} label="Home" to={"/"} />
                                    {/* <div className={classes.navIcon} id="watch-nav-icon">
                                        <WatchIcon />
                                    </div>
                                    <LinkTab value={2} className={`${classes.topNavLabel} ${nav === 2 ? classes.selectedNavLabel : null}`} label="Watch" to={"/watch"} /> */}
                                    <div className={classes.navIcon} id="connect-nav-icon">
                                        <ConnectIcon />
                                    </div>
                                    <LinkTab value={1} className={`${classes.topNavLabel} ${nav === 1 ? classes.selectedNavLabel : null}`} label="Connect" to={"/connect"} />
                                </StyledTabs>
                            }
                        </div>
                        {!isSmallScreen &&
                            <>
                                <div className={`${classes.avatarTag}`} onClick={handleClick}>
                                    <div className={classes.avatarNameTag}>
                                        {userData.preferred_name}
                                    </div>
                                    <Button classes={{ root: classes.avatarButton }}>
                                        <UserAvatar />
                                    </Button>
                                </div>
                                <Menu
                                    id="learner-user-avatar-basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    classes={{ paper: classes.menuPaper, list: classes.menuList }}
                                >
                                    <Link style={{ textDecoration: "none", color: "black" }}
                                        onClick={handleClose}
                                        to={`/profile/${user.email}`}>
                                        <MenuItem classes={{ root: classes.menuItem }}> My Profile </MenuItem>
                                    </Link>
                                    {/* Logic Flow:
                                    1. Check if user role =1 (Student). If yes, route users to /profile/learner/editprofile
                                    2. If user is coach, but logged in as student and viewing course as student route coach to /profile/learner/editprofile
                                    3. Else user is an admin route them to /acp/staff/editprofile  
                                */}
                                    {
                                        userRole.id === 5 ?
                                            <MenuItem onClick={() => {
                                                setCoachSwitchToACPStorage();
                                                window.location.assign('/');//re-routes
                                            }} classes={{ root: classes.menuItem }}>Switch to ACP</MenuItem> :
                                            null
                                    }
                                    <MenuItem onClick={() => handleLogout()} classes={{ root: classes.menuItem }}>Sign Out</MenuItem>
                                </Menu>
                            </>
                        }
                        {isSmallScreen &&
                            <>
                                <IconButton onClick={() => { setDrawerIsOpen(true) }}>
                                    <MenuIcon />
                                </IconButton>
                                <Drawer
                                    anchor="left"
                                    open={drawerIsOpen}
                                    onClose={() => { setDrawerIsOpen(false) }}
                                    classes={{
                                        paper: classes.drawerPaper
                                    }}
                                >
                                    <div className={classes.closeWrap}>
                                        <IconButton onClick={() => { setDrawerIsOpen(false) }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <MobileLearnerInfo />
                                    <MobileTabs setDrawerIsOpen={setDrawerIsOpen} />
                                    <p onClick={() => handleLogout()} className={classes.logout}>Sign Out</p>
                                </Drawer>
                            </>

                        }
                    </div>
                </div>
                {props.children}
            </div>
        </>
    )
}