import { makeStyles } from '@mui/styles';

const useHeaderStyles = makeStyles((theme) => ({
    parentWrapper: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        overflowX: "hidden",
        backgroundColor: "#F3F3F3",
        height: "100vh"
    },
    header: {
        width: "100%",
        padding: "29px 0px 29px 0px",
        backgroundColor: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "12px"
    },
    headerContent: {
        width: "100%",
        maxWidth: "1140px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 30px 0px 30px"
    },
    userDetails: {
        display: "flex",
        alignItems: "center",
    },
    title: {
        height: '23px',
        fontFamily: 'Raleway',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '23px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: "#000000",
        margin: "0px",
        position: "relative",
        top: "9px",
        transition: "top 1s",
        [theme.breakpoints.down("xs")]: {
            fonstSize: "16px"
        }
    },
    titleNormal: {
        top: "0px"
    },
    subtitle: {
        height: "16px",
        fontFamily: "Raleway",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "14px",
        letterSpacing: "0em",
        textAlign: "left",
        color: "#5E5E5E",
        margin: "0px"
    },
    image: {
        marginRight: "15px",
        maxWidth: "100px",
        height: "44px",
        verticalAlign: "middle",
        margin: "auto",
        paddingRight: "10px"
    },
    menuPaper: {
        marginTop: "10px"
    },
    menuList: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    menuItem: {
        paddingLeft: "22px",
        paddingRight: "23px",
        paddingTop: "13px",
        paddingBottom: "13px",
        fontFamily: 'Raleway !important',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: "700 !important",
        textAlign: 'center !important',
    },
    avatarTag: {
        position: "relative",
        height: "45px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        borderRadius: "100px",
        '&:hover': {
            cursor: "pointer"
        }
    },
    avatarNameTag: {
        fontFamily: "Nunito",
        fontWeight: 500,
        paddingLeft: "16px",
        color: "#1D3969",
        marginRight: "-10px"
    },
    avatarButton: {
        width: "40px",
        height: "40px",
        borderRadius: "40px",
        position: "absolute",
        left: "10px",
    },
    /** User Avatar */
    avatar: {
        //For screens greater than small screen width
        [theme.breakpoints.up('sm')]: {
            width: "40px !important",
            height: "40px !important",
            border: "2.5px solid #63C29D !important"
        },

        //For mobile screen only less than 600px
        [theme.breakpoints.down('sm')]: {
            width: "76px !important",
            height: "76px !important",
            marginRight: "19px",
            border: `2px solid ${theme.MyKenzie.cardColors.secondary}`
        }
    },
    topNavLabel: {
        position: "relative",
        fontFamily: 'Nunito !important',
        fontSize: '16px !important',
        fontStyle: 'normal !important',
        fontWeight: '600 !important',
        lineHeight: '21px !important',
        textAlign: 'center !important',
        color: "#1D3969 !important",
        textTransform: "none !important",
        opacity: '1 !important'
    },
    selectedNavLabel: {
        fontWeight: "800 !important"
    },
    topNavTabs: {
        marginLeft: "18px",
        '& a': {
            marginLeft: "20px"
        }
    },
    navIcon: {
        position: "absolute",
        top: "14px",
        '&#home-nav-icon': {
            left: "16px"
        },
        // '&#watch-nav-icon': {
        //     left: "124px"
        // },
        '&#connect-nav-icon': {
            left: "124px"
        }
    },
    drawerPaper: {
        width: "100%",
        padding: "18px 18px",
        boxSizing: "border-box"
    },
    closeWrap: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    logout: {
        fontFamily: 'Raleway',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '33px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: "#5F5F5F",
        cursor: 'pointer',
        marginLeft: "19px",
        marginTop: "auto"
    },
    mkLogo: {
        width: "429px",
        height: "149px",
        [theme.breakpoints.down('sm')]: {
            width: "251px"
        },
    },
    mkHeader: {
        padding: "27px 0px 25px 0px",
        [theme.breakpoints.down('md')]: {
            boxSizing: "border-box",
            padding: "30px 31px 29px 40px",
            width: "100vw",
            '& .MuiAvatar-root': {
                height: "34px",
                width: "34px",
            },
        }
    },

    /** User details on mobile menu */
    userName: {
        margin: 0,
        fontFamily: 'Raleway',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '30px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#272727'
    },
    userEmail: {
        margin: 0,
        fontFamily: 'Raleway',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#414042',
    },

    /***************** */
    /** Mobile styles */
    avatarWrapper:{
        [theme.breakpoints.down('md')]: {
            display: "flex",
            alignItems: "center",
            marginBottom: "34px"
        }
    }
}))

export default useHeaderStyles