const userRoles = [
    {
        "id": 1,
        "uiName": "Student",
        "dbName": "student",
        "isVisibleInACP": false,
        "isVisibleInSupportPanel": false,
        "acpPermissions": null//dont have permission to acp
    },
    {
        "id": 2,
        "uiName": "Facilitator",
        "dbName": "facilitator",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 3
    },
    {
        "id": 3,
        "uiName": "Senior Software Engineer",
        "dbName": "facilitator",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 1
    },
    {
        "id": 4,
        "uiName": "Learner Success Advisor",
        "dbName": "student services advisor",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 4
    },
    {
        "id": 5,
        "uiName": "Coach",
        "dbName": "coach",
        "isVisibleInACP": false,
        "isVisibleInSupportPanel": false,
        "displayOrder": 5,
        "acpPermissions": {
            "menuAccess": {
                "supportRequests": true,
                "announcements": true,
                "adminSettings": false,
                "adminSettingsManageCohorts": false,
                "adminSettingsManageCohortMembers": false,
                "adminSettingsManageLearners": false,
                "cohortSettings": false,
                "calendarSettings": false,
                "staffSettings": false,
                "webToolsSettings": false,
                "editProfile": true,
                "profile": true
            },
            "functions": {
                "isAdmin": false,
                "supportRequestsCanSeeAllRequests": false,
                "supportRequestsAccessToAssignedCohortRequests": true,
                "supportRequestsCanSeePersonalRequests": false,
                "supportRequestsCanRespondToPersonalRequests": false,
                "supportRequestsCanRespondToAssignedCohortRequests": true,
                "annoucementsCanCreateAnnouncement": false,
                "annoucementsCanSeeAnnouncementHistory": true,
                "annoucementsCanSendAnnoucementsToEveryone": false,
                "annoucementsCanSendAnnoucementsToAssignedCohort": false,
                "annoucementsCanEditAnnoucements": false,
                "annoucementsCanDeleteAnnoucements": false,
                "cohortSettingsCanManageCalendarSettings": false,
                "cohortSettingsCanManageWebToolSettings": false,
                "cohortSettingsCanManagerSupportStaffSettings": false,
                "adminSettingsCreateCohort": false
            }

        }
    },
    {
        "id": 6,
        "uiName": "Career Advisor",
        "dbName": "placement advisor",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 6
    },
    {
        "id": 7,
        "uiName": "Admissions Counselor",
        "dbName": "admission advisor",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 7
    },
    {
        "id": 8,
        "uiName": null,
        "dbName": null,
        "isVisibleInACP": false,
        "isVisibleInSupportPanel": false
    },
    {
        "id": 9,
        "uiName": "Program Manager",
        "dbName": "admin",
        "isVisibleInACP": false,
        "isVisibleInSupportPanel": false,
        "acpPermissions": {
            "menuAccess": {
                "supportRequests": true,
                "announcements": true,
                "adminSettings": true,
                "adminSettingsManageCohorts": true,
                "adminSettingsManageCohortMembers": true,
                "adminSettingsManageLearners": false,
                "cohortSettings": true,
                "calendarSettings": true,
                "staffSettings": true,
                "webToolsSettings": true,
                "editProfile": true,
                "profile": true
            },
            "functions": {
                "isAdmin": true,
                "supportRequestsCanSeeAllRequests": true,
                "supportRequestsAccessToAssignedCohortRequests": true,
                "supportRequestsCanSeePersonalRequests": true,
                "supportRequestsCanRespondToPersonalRequests": true,
                "supportRequestsCanRespondToAssignedCohortRequests": true,
                "annoucementsCanCreateAnnouncement": true,
                "annoucementsCanSeeAnnouncementHistory": true,
                "annoucementsCanSendAnnoucementsToEveryone": true,
                "annoucementsCanSendAnnoucementsToAssignedCohort": true,
                "annoucementsCanEditAnnoucements": true,
                "annoucementsCanDeleteAnnoucements": true,
                "cohortSettingsCanManageCalendarSettings": true,
                "cohortSettingsCanManageWebToolSettings": true,
                "cohortSettingsCanManagerSupportStaffSettings": true,
                "adminSettingsCreateCohort": true
            }
        }
    }, {
        "id": 10,
        "uiName": "Senior UX Engineer",
        "dbName": "ux instructor",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 2
    },
    {
        "id": 11,
        "uiName": "Limited Admin", // Not shown in UI 
        "dbName": "limited admin",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": false,
        "acpPermissions": {
            "menuAccess": {
                "supportRequests": false,
                "announcements": true,
                "adminSettings": false,
                "adminSettingsManageCohorts": true,
                "adminSettingsManageCohortMembers": false,
                "adminSettingsManageLearners": false,
                "cohortSettings": false,
                "calendarSettings": true,
                "staffSettings": true,
                "webToolsSettings": true,
                "editProfile": true,
                "profile": true
            },
            "functions": {
                "isAdmin": true,
                "supportRequestsCanSeeAllRequests": false,
                "supportRequestsAccessToAssignedCohortRequests": false,
                "supportRequestsCanSeePersonalRequests": false,
                "supportRequestsCanRespondToPersonalRequests": false,
                "supportRequestsCanRespondToAssignedCohortRequests": false,
                "annoucementsCanCreateAnnouncement": true,
                "annoucementsCanSeeAnnouncementHistory": true,
                "annoucementsCanSendAnnoucementsToEveryone": true,
                "annoucementsCanSendAnnoucementsToAssignedCohort": true,
                "annoucementsCanEditAnnoucements": true,
                "annoucementsCanDeleteAnnoucements": true,
                "cohortSettingsCanManageCalendarSettings": false,
                "cohortSettingsCanManageWebToolSettings": false,
                "cohortSettingsCanManagerSupportStaffSettings": false,
                "adminSettingsCreateCohort": false
            }
        }
    },
    {
        "id": 12,
        "uiName": "Cyber Security Manager",
        "dbName": "cyber security instructor",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 8
    },
    {
        "id": 13,
        "uiName": "Financial Aid Advisor",
        "dbName": "financial_aid_advisor",
        "isVisibleInACP": false,
        "isVisibleInSupportPanel": true,
        "displayOrder": 9
    },
    {
        "id": 14,
        "uiName": "Subject Matter Expert",
        "dbName": "subject matter expert",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 10
    },
    {
        "id": 15,
        "uiName": "Support",
        "dbName": "support",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": false,
        "displayOrder": 11
    },
    {
        "id": 16,
        "uiName": "Subject Matter Expert",
        "dbName": "sme (admin)",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 10,
        "acpPermissions": {
            "menuAccess": {
                "supportRequests": true,
                "announcements": true,
                "adminSettings": true,
                "adminSettingsManageCohorts": true,
                "adminSettingsManageCohortMembers": true,
                "adminSettingsManageLearners": false,
                "cohortSettings": true,
                "calendarSettings": true,
                "staffSettings": true,
                "webToolsSettings": true,
                "editProfile": true,
                "profile": true
            },
            "functions": {
                "isAdmin": true,
                "supportRequestsCanSeeAllRequests": true,
                "supportRequestsAccessToAssignedCohortRequests": true,
                "supportRequestsCanSeePersonalRequests": true,
                "supportRequestsCanRespondToPersonalRequests": true,
                "supportRequestsCanRespondToAssignedCohortRequests": true,
                "annoucementsCanCreateAnnouncement": true,
                "annoucementsCanSeeAnnouncementHistory": true,
                "annoucementsCanSendAnnoucementsToEveryone": true,
                "annoucementsCanSendAnnoucementsToAssignedCohort": true,
                "annoucementsCanEditAnnoucements": true,
                "annoucementsCanDeleteAnnoucements": true,
                "cohortSettingsCanManageCalendarSettings": true,
                "cohortSettingsCanManageWebToolSettings": true,
                "cohortSettingsCanManagerSupportStaffSettings": true,
                "adminSettingsCreateCohort": true
            }
        }
    },
    {
        "id": 17,
        "uiName": "Facilitator",
        "dbName": "facilitator (admin)",
        "isVisibleInACP": true,
        "isVisibleInSupportPanel": true,
        "displayOrder": 3,
        "acpPermissions": {
            "menuAccess": {
                "supportRequests": true,
                "announcements": true,
                "adminSettings": true,
                "adminSettingsManageCohorts": true,
                "adminSettingsManageCohortMembers": true,
                "adminSettingsManageLearners": false,
                "cohortSettings": true,
                "calendarSettings": true,
                "staffSettings": true,
                "webToolsSettings": true,
                "editProfile": true,
                "profile": true
            },
            "functions": {
                "isAdmin": true,
                "supportRequestsCanSeeAllRequests": true,
                "supportRequestsAccessToAssignedCohortRequests": true,
                "supportRequestsCanSeePersonalRequests": true,
                "supportRequestsCanRespondToPersonalRequests": true,
                "supportRequestsCanRespondToAssignedCohortRequests": true,
                "annoucementsCanCreateAnnouncement": true,
                "annoucementsCanSeeAnnouncementHistory": true,
                "annoucementsCanSendAnnoucementsToEveryone": true,
                "annoucementsCanSendAnnoucementsToAssignedCohort": true,
                "annoucementsCanEditAnnoucements": true,
                "annoucementsCanDeleteAnnoucements": true,
                "cohortSettingsCanManageCalendarSettings": true,
                "cohortSettingsCanManageWebToolSettings": true,
                "cohortSettingsCanManagerSupportStaffSettings": true,
                "adminSettingsCreateCohort": true
            }
        }
    }
];

export default userRoles;