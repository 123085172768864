import moment from "moment";

const setLocalStorageForOldSurveys = (index) => {
  if (index > 0)
    for (let j = 0; j < index; j++) {
      window.localStorage.setItem(`mykenzie-learner-survey-${j}`, true);
    }
};

const checkAndShowSurvey = (index, setShowSurvey) => {
  if (!window.localStorage.getItem(`mykenzie-learner-survey-${index}`)) {
    setShowSurvey(true);
  }
};



function todayIsOnOrBetweenCutoffs(startDate, cutoff) {
  const today = moment(new Date(), "MM/DD/YY")
  return today.isBetween(moment(startDate.format('MM/DD/YY'), "MM/DD/YY"), moment(cutoff.format('MM/DD/YY'), "MM/DD/YY"))
    || today.isSame(moment(startDate.format('MM/DD/YY'), "MM/DD/YY"))
}

export function processLatestDateRange(setCurrentSurveyDate, setShowSurvey) {
  //handles final survey date and beyond
  setLocalStorageForOldSurveys(3);
  setCurrentSurveyDate(3);
  checkAndShowSurvey(3, setShowSurvey);
}
export const processDateRanges = (dateRanges, setCurrentSurveyDate, setShowSurvey) => {
  for (let i in dateRanges) {
    const [start, end] = dateRanges[i];
    if (todayIsOnOrBetweenCutoffs(start, end)) {
      setLocalStorageForOldSurveys(i);
      setCurrentSurveyDate(i);
      checkAndShowSurvey(i, setShowSurvey);
    }
  }
};