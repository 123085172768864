import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
// import { makeStyles } from '@mui/styles';

import Announcements from "./Announcements";
import ConnectPanel from './connectPanel';
import Events from "./events";
import MyCourse from "./MyCourse";
import MyCourseLaunchpad from './MyCourseLaunchpad';
import SupportTeams from './supportTeams';
import WebTools from './webTools';
import DashboardSkeleton from "./DashboardSkeleton";
import SurveyModal from "./SurveyModal";

import { AppDataContext } from "../../../context";
import { LearnersDataContext } from '../../context';
import useStyles from "./styles";

export default function Home(props) {

  const { user } = useAuth0()
  const classes = useStyles();
  const theme = useTheme();

  const { userData } = useContext(AppDataContext);
  const { userCourse, setHelpOpen, showSurvey, handleSurveyClick  } = useContext(LearnersDataContext);
  
  useEffect(() => {
    if(props.help) {
      setHelpOpen(true)
    }
  }, [])

  useEffect(() => {
    if (user && user.email && userCourse) {
      
      //Get the first courseId, receieved from the response
      //We might need update this in future for multi course. 
      const courseId = userData.mk_user_courses[0].course_id;
      const cohortId = userData.mk_user_courses[0].cohort_id;
      
      window.pendo.initialize({
        disableCookies: true,
        visitor: {
          id: user.email,
          email: user.email,
          fullName: user.name,
          firstName: user.family_name,
          lastName: user.given_name,
          course_id: courseId ? courseId : null,
          cohort_id: cohortId ? cohortId : null,
          return_url: '',
        },
        account: {
          id: user.email,
          name: user.name,
          email: user.email,
        }
      });
      setIsLoading(false);
    }
  }, [user, userData]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {
          isLoading ?

          <DashboardSkeleton />
          :
          <>
            {showSurvey ? <SurveyModal onClose={handleSurveyClick}/> : null}
            <div className={classes.announcments}><Announcements /></div>
            <div className={classes.contentRight}>
              {!isSmallScreen ?
                <>
                  {userCourse?.course && userCourse.course?.prestart ? 
                    <MyCourseLaunchpad /> :<MyCourse />
                  }
                  <Events />
                </> :
                <>
                  <WebTools />
                  {userCourse && userCourse?.course && userCourse.course?.prestart ?
                    <MyCourseLaunchpad /> :
                    <MyCourse />
                  }
                  <Events />
                  <ConnectPanel />
                </>
              }
            </div>

            <div className={classes.contentLeft}>
              {!isSmallScreen ?
                <>
                  <WebTools />
                  <ConnectPanel />
                  <SupportTeams />
                </> :
                <SupportTeams />
              }
            </div>
          </>
        }
      </div>
    </div>
  );
}