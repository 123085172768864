import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        maxWidth: "1135px",
        [theme.breakpoints.down('sm')]: {
            minWidth: "250px"
        }
    },
    annWrap: {
        [theme.breakpoints.down('sm')]: {
            width: "89.1vw"
        },
    },
    carouselNav: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
            marginBottom: "10px"
        }
    },
    iconSize: {
        fontSize: "12px !important",
        color: `${theme.MyKenzie.cardColors.tertiary} !important`,
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px !important",
            paddingBottom: "10px"
        }
    },
    annContent: {
        position: "relative"
    },
    annBody: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        maxWidth: "1135px",
        padding: "8px 25px 14px 25px",
        backgroundColor: "#FFFFFF",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        marginRight: "-1px",
        '& p': {
            fontFamily: "Nunito",
            fontWeight: 500
        },
        '& #toggle-read-option': {
            position: "absolute",
            bottom: 30,
            right: 24,
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: "bold",
            color: "#C2573F",
            margin: 0,
            backgroundColor: "white",
            paddingLeft: "2px",
            '&:hover': {
                cursor: "pointer"
            },
            [theme.breakpoints.down('sm')]: {
                right: 35,
                bottom: 12
            }
        }
    },
    annNav: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "25px",
        paddingRight: "25px",
        marginRight: "-1px",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "column",
        },
        backgroundColor: "#FFE8E2",
        borderRadius: "0px 0px 8px 8px"
    },
    annSubtext: {
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: "#545454",
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            paddingTop: "10px",
            height: "45px"
        }
    },
    leftArrow: {
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        transition: "background-color 500ms",
        "@global": {
            '.leftArrowSvg': {
                '&:hover': {
                    cursor: "pointer",
                }
            }
        },
        '&:hover': {
            backgroundColor: "rgb(225,225,225)",
            cursor: "pointer",
        },
        [theme.breakpoints.down('sm')]: {
            transform: "scale(1.5)",
            marginRight: "5px"
        }
    },
    rightArrow: {
        height: "20px",
        width: "20px",
        marginLeft: "-3px",
        borderRadius: "50%",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        transition: "background-color 500ms",
        '@global': {
            '.rightArrowSvg': {
                '&:hover': {
                    cursor: "pointer",
                }
            }
        },
        '&:hover': {
            backgroundColor: "rgb(225,225,225)",
            cursor: "pointer",
        },
        [theme.breakpoints.down('sm')]: {
            transform: "scale(1.5)",
            marginLeft: "3px"
        }
    },
    circleGroup: {
        display: "flex",
        justifyContent: "space-around",
        paddingRight: "5px",
        paddingLeft: "5px",
        paddingTop: "2px"
    },
    circle: {
        height: "7px",
        width: "7px",
        borderRadius: "50%",
        border: "1px solid #C2573F",
        backgroundColor: "#C2573F",
        marginLeft: "3px",
        marginRight: "5px",
        '&:hover': {
            cursor: "pointer"
        },
        [theme.breakpoints.down('sm')]: {
            height: "16px",
            width: "16px"
        }
    },
    circleOutlined: {
        height: "7px",
        width: "7px",
        borderRadius: "50%",
        border: "1px solid #C2573F",
        marginLeft: "3px",
        marginRight: "5px",
        '&:hover': {
            cursor: "pointer"
        },
        [theme.breakpoints.down('sm')]: {
            height: "16px",
            width: "16px"
        }
    },
    circleX: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid rgba(104, 104, 104, 1)",
        borderRadius: "50%",
        height: "13px",
        width: "13px",
        "&:hover": {
            backgroundColor: "rgb(240,240,240);",
            cursor: "pointer"
        },
        [theme.breakpoints.down('sm')]: {
            height: "13px",
            width: "13px"
        }
    },
    X: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    annAnimation: {
        animationName: "$annFade",
        animationDuration: "1s",
        animationIterationCount: 1,
        transitionTimingFunction: "ease-in-out"
    },
    '@keyframes annFade': {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    },
}))
export default useStyles;