import { DashboardSection, SectionTitle, SectionTitleContainer, SectionBody, HeadingText, SettingsBodyText, BodyText } from '../Dashboard'
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import { useACPDataContextV2 } from "../../../context/ACPDataStoreV2"
import { useEffect, useState } from 'react'
import styled from "@emotion/styled"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useForm } from "react-hook-form";
import { findSettingsTablesToUpdate } from '../formHelpers'
import CircularLoader from '../../../../sharedComponents/CircularLoader';
import { useAlert } from 'react-alert';
import { WEBTOOL_MAPPINGS } from '../../../constants';
import { SearchCourseField } from './SearchCourseField';


export default function MKSettingsPanel() {
    const {
        hasCurrentCohortAdminAccess,
        cohort,
        updateSingleCohort,
        calendarInfo,
        courses,
        courseAssociations,
        setCourseAssociations,
        webtools,
        updateWebtools,
        updateCalendar,
        selectedCohortId,
        addCourses,
        removeCourses
    } = useACPDataContextV2()

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [isEditMode, setIsEditMode] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [warningModalOpen, setWarningModalOpen] = useState(false)
    const [warningModalCleared, setWarningModalCleared] = useState(false)
    const [emptyCourseCleared, setEmptyCourseCleared] = useState(false)
    const alert = useAlert()
    function handleModalClose() {
        setWarningModalCleared(true)
        setWarningModalOpen(false)
    }
    function handleClearForm() {
        setIsEditMode(false)
        setIsSubmitting(false)
    }
    async function handleSubmitForm(data) {
        const updates = findSettingsTablesToUpdate(webtools, data, selectedCohortId, courses, courseAssociations)
        if (!(updates?.courseAdditions || updates?.courseDeletions) || warningModalCleared) {
            setIsSubmitting(true)
            const promises = []
            if (updates) {
                if (updates.cohort) {
                    promises.push(updateSingleCohort(cohort, updates.cohort, selectedCohortId))

                }
                if (updates.webtools) {
                    promises.push(updateWebtools(updates.webtools, selectedCohortId))
                }
                if (updates.calendar) {
                    promises.push(updateCalendar(updates.calendar, calendarInfo, selectedCohortId, cohort?.cohort_name))
                }
                if (updates.courseAdditions) {
                    promises.push(addCourses(updates.courseAdditions))
                }
                if (updates.courseDeletions) {
                    promises.push(removeCourses(updates.courseDeletions))
                }
                if (promises.length) {
                    const responses = await Promise.all(promises)
                    const didUpdate = responses.filter(r => r?.didUpdate)
                    const didNotUpdate = responses.filter(r => !r?.didUpdate)
                    if (didUpdate.length && !didNotUpdate.length) {
                        alert.success('Settings updated Successfully!')
                    }
                    else {
                        for (let e of didUpdate) {
                            alert.success(`Successfully Updated Values in ${e.name} Table`)
                        }
                        for (let e of didNotUpdate) {
                            alert.error(e.message || `Failed to update values in ${e.name} Table`, { type: 'error' })

                        }
                    }
                }
            }
            setIsSubmitting(false)
            setIsEditMode(false)
        } else {
            setWarningModalOpen(true)
        }
    }


    function handleCourseDelete(lms_id) {
        let active = [...courseAssociations["active"]];
        let index = active.findIndex(i => i.lms_course_id === lms_id);
        active.splice(index, 1); 
        setCourseAssociations({ ...courseAssociations, active });
    }
    useEffect(() => {
        if (!isEditMode) {
            reset()
        }
    }, [isEditMode])

    const DefaultLayout = () => (
        <SectionBody>

            <HeadingText>
                Associated Canvas Courses (this term)
            </HeadingText>
            {courseAssociations?.active?.map((course, i) => (
                <SettingsBodyText key={i}>
                    [{course.lms_course_id}] {course.coursename}

                </SettingsBodyText>
            ))}
            {courseAssociations?.active.length === 0 &&
                <SettingsBodyText>
                    []
                </SettingsBodyText>
            }

            <HeadingText>
                Cohort Calendar ID [Associated Google Group email]
            </HeadingText>
            <SettingsBodyText className="url">
                {calendarInfo?.google_calendar_id || "Could not retrieve Calendar Info"}
            </SettingsBodyText>

            <HeadingText>
                Cohort Calendar [embedded url is required]
            </HeadingText>
            <SettingsBodyText className="url">
                {calendarInfo?.calendar_url || "Could not retrieve Calendar Info"}
            </SettingsBodyText>



            <HeadingText>Study Session Zoom URL</HeadingText>
            <SettingsBodyText className="url">{cohort?.zoom_url || 'No Zoom URL Set for Cohort'}</SettingsBodyText>
            <HeadingText>Study Session Zoom Personal Meeting ID</HeadingText>
            <SettingsBodyText className="url">{cohort?.zoom_host_id || 'No Zoom Personal Meeting ID Set for Cohort'}</SettingsBodyText>

            <HeadingText>Inscribe Community Link</HeadingText>
            <SettingsBodyText className="url">{cohort?.inscribeUrl || 'No Inscribe ID Set for Cohort'}</SettingsBodyText>

            <HeadingText>Slack Alerts Channel</HeadingText>
            <SettingsBodyText className="url">{cohort?.slackUrl || "No Slack ID Set for Cohort"}</SettingsBodyText>

            <HeadingText>Online Tools</HeadingText>
            <WebToolContainer>

                {WEBTOOL_MAPPINGS?.map((tool, i) => {
                    const existing = webtools.find(t => t.webtool.id === tool.id)
                    return (
                        <WebtoolItem key={i}>
                            <Checkbox checked={existing?.is_active || false} sx={checkboxStyles} disabled />
                            <BodyText>{tool.name}</BodyText>
                        </WebtoolItem>
                    )
                })
                }
            </WebToolContainer>

        </SectionBody>
    )

    const EditLayout = () => (
        <SectionBody>
            <Modal open={warningModalOpen} onClose={handleModalClose} sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <WarningModal>
                    <WarningHeader>Notice</WarningHeader>
                    <BodyText>A course association for this cohort will be changed</BodyText>
                    <BodyText>Please ensure Inscribe, Slack Alerts Channel, and Study Session information has been updated if necessary.</BodyText>
                    <TextButton onClick={handleModalClose}>Double Check</TextButton>
                </WarningModal>
            </Modal>
            <HeadingText>
                Associated Canvas Courses (this term)
            </HeadingText>
            {courseAssociations?.active &&
                courseAssociations.active.length > 0 &&
                courseAssociations?.active?.map((course, i) => (
                    <BodyText key={course.lms_course_id}>
                        <CourseItem>
                            [{course.lms_course_id}] {course.coursename}
                            <IconButton
                                className='delete-cohort_course'
                                data-testid={`delete-cohort_course-${course.lms_course_id}`}
                                onClick={() => { handleCourseDelete(course.lms_course_id); }}>
                                <DeleteIcon />
                            </IconButton>
                        </CourseItem>
                    </BodyText>
                ))
            }
            {courseAssociations?.active &&
                courseAssociations.active.length === 0 &&
                !emptyCourseCleared &&
                <BodyText>
                    <CourseItem>
                        []
                        <IconButton
                            onClick={() => { setEmptyCourseCleared(true) }}>
                            <DeleteIcon />
                        </IconButton>
                    </CourseItem>
                </BodyText>
            }
            <SearchCourseField />

            <HeadingText>
                Cohort Calendar ID [Associated Google Group email]

            </HeadingText>

            <FullRowInputField {...register('calendar.google_calendar_id')} placeholder={calendarInfo?.google_calendar_id || ''} />


            <HeadingText>
                Cohort Calendar [embedded url is required]
            </HeadingText>

            <FullRowInputField {...register('calendar.calendar_url')} placeholder={calendarInfo?.calendar_url || ''} />






            <HeadingText>Study Session Zoom URL</HeadingText>
            <FullRowInputField  {...register('cohort.zoom_url')} placeholder={cohort?.zoom_url || ''} />
            <HeadingText>Study Session Zoom Personal Meeting ID</HeadingText>
            <FullRowInputField  {...register('cohort.zoom_host_id')} placeholder={cohort?.zoom_host_id || ''} />

            <HeadingText>Inscribe Community ID</HeadingText>
            <BodyText as='div' >
                <span>
                    https://inscribe.education/main/kenzie/
                </span>
                <InlineInputField
                    style={errors?.cohort?.inscribe_community_id && { borderColor: 'red' }}
                    {...register('cohort.inscribe_community_id', { pattern: /^\d+$/ })}
                    placeholder={cohort.inscribe_community_id || ''}
                />
                {errors?.cohort?.inscribe_community_id ?
                    <span style={{ position: 'relative', color: 'red' }}> *Numbers Only</span>
                    : null
                }


            </BodyText>
            <HeadingText>Slack Alerts Channel ID</HeadingText>
            <BodyText as='div'>

                <span>https://app.slack.com/client/TCNHBFEG3/</span>
                <InlineInputField
                    {...register('cohort.slack_channel_id')}
                    placeholder={cohort.slack_channel_id || ''}
                />
            </BodyText>

            <HeadingText>Online Tools</HeadingText>
            <WebToolContainer>
                {WEBTOOL_MAPPINGS?.map((tool, i) => {
                    const existing = webtools.find(t => t.webtool.id === tool.id)
                    return (
                        <WebtoolItem key={i}>
                            <Checkbox  {...register(`webtools.${tool.name}-${tool.id}`)} defaultValue={existing?.is_active || false} defaultChecked={existing?.is_active || false} sx={editModeCheckboxStyles}

                            />
                            <BodyText > {tool.name}</BodyText>
                        </WebtoolItem>
                    )
                })}
            </WebToolContainer>

        </SectionBody >
    )

    return (
        <DashboardSection gridArea='mks'>
            <SectionTitleContainer>
                <SectionTitle>
                    MyKenzie Settings
                </SectionTitle>
                {hasCurrentCohortAdminAccess ?
                    <>
                        {!isEditMode ?
                            <EditIcon sx={{ color: '#FFF', marginLeft: 'auto', cursor: 'pointer', width: '19px' }} onClick={() => setIsEditMode(true)} />
                            :
                            <>
                                <CheckCircleIcon onClick={handleSubmit(handleSubmitForm)} sx={{ width: '20px', height: '20px', color: '#ACFFE1', cursor: 'pointer', marginRight: '10px', marginLeft: 'auto', }} />
                                <HighlightOffIcon onClick={handleClearForm} sx={{ width: '20px', height: '20px', color: '#FF9999', cursor: 'pointer' }} />

                            </>
                        }
                    </>
                    : null
                }
            </SectionTitleContainer>
            {!isSubmitting ?
                <>
                    {!isEditMode ? <DefaultLayout /> : <EditLayout />}
                </>
                :
                <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>

                    <CircularLoader />
                </div>
            }
        </DashboardSection>
    )
}

const checkboxStyles =
{
    '& .MuiSvgIcon-root': { fontSize: 18 },
    '&.Mui-checked': {
        color: '#1D3969',
        opacity: '.5'

    },

}
const editModeCheckboxStyles =
{
    '& .MuiSvgIcon-root': { fontSize: 18 },
    '&.Mui-checked': {
        color: '#1D3969',
    },

}
const FullRowInputField = styled.input`
height: 36px;
width: 100%;
box-sizing: border-box;
border: 1px solid #000000;
border-radius: 5px;
padding: 3px 5px;
`
const InlineInputField = styled.input`
border: 0;
margin:0;
display: inline-block;
background-color: transparent;
border:0;
border-bottom: 1px solid #000000;
/* border-radius: 5px; */
margin-left: 2px
`
const WebToolContainer = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
flex-flow: row wrap;
`
const WebtoolItem = styled.div`
display: flex;
align-items: center;
width: 30%;
margin-left: 8px;

& input{
    margin-bottom: 16px;
}
& p{
    margin-left: 5px;
    margin-bottom: 0;
}
`
const CourseItem = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
`
const WarningModal = styled.div`
width: 570px;
background: #FFFFFF;
border-radius: 10px;
padding: 30px;
display: flex;
flex-direction: column;
`
const WarningHeader = styled.div`
color: #C2573F;
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 27px;
margin-bottom: 10px;
`
const TextButton = styled.div`
height: 35px;
padding: 2px;
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
display: flex;
align-items: center;
text-align: right;
color: #1D3969;
clear: both;
cursor: pointer;
white-space: nowrap;
align-self: end;
`