import React, { useEffect } from "react";
// import {ArrowBackIos, ArrowForwardIos, Circle, CircleOutlined} from '@mui/icons-material';
import moment from 'moment';
import { getAbbreviatedPostedBy } from "../../acp/pages/announcements/helpers";
import { PROGRAMS } from "../../acp/constants";
import { ReactComponent as LeftArrow } from '../../icons/carousel_left_arrow.svg';
import { ReactComponent as RightArrow } from '../../icons/carousel_right_arrow.svg';
import { Card } from "../../learner/components/Card";
import { useTheme, useMediaQuery } from "@mui/material";
import useStyles from "./styles";

import HtmlParser from "./HtmlParser";


export default function AnnouncementCorousal({
    announcements = [],
    formValues
}) {
    const classes = useStyles();
    const theme = useTheme();

    const [carouselIndex, setCarouselIndex] = React.useState(0)
    const [readOption, setReadOption] = React.useState(null)
    const [autoCarousel, setAutoCarousel] = React.useState(true)

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (carouselIndex >= announcements.length && announcements.length > 0) {
            setCarouselIndex(announcements.length - 1)
        }
    })

    useEffect(() => {
        let interval;
        if (autoCarousel) {
            interval = setInterval(() => {
                let carouselArrow = document.getElementById("right-arrow-announcements");
                if (carouselArrow) {
                    carouselRight()
                }
            }, 10000);
        } else {
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [carouselIndex, autoCarousel])

    useEffect(() => {
        let characters = countCharacters(announcements[carouselIndex]?.body ? announcements[carouselIndex].body : null)
        let lineBreaks = countLineBreaks(announcements[carouselIndex]?.body ? announcements[carouselIndex].body : null)

        if (lineBreaks > 1 || characters >= 340) {
            setReadOption("... Read More")
        } else {
            setReadOption(null)
        }
    }, [announcements, carouselIndex])

    const formatStartTime = startTime => {
        const d = new Date(startTime)
        const tzName = d.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
        return moment(startTime).format('MM/DD/YYYY @ h[:]mmA') + ` ${tzName}`;
    }

    const announcementSubtext = (announcement, formValues) => {

        let audience;

        if ((formValues?.cohort_id === null) && (formValues?.program_id !== null)) {
            const program = PROGRAMS.find((program) => program.id === formValues.program_id)
            audience = `to ${program.title}`
        } else {
            audience = announcement?.["cohort"]?.["cohort_name"] ?
                `to ${announcement["cohort"]["cohort_name"]}` : ""
        }

        const author = getAbbreviatedPostedBy(announcement);

        const schedule = announcement.post_schedule ? ` on ${formatStartTime(announcement.post_schedule)}` : ""

        return `Posted by ${author} ${audience}${schedule}`
    }

    const circleSelect = (index) => {
        setCarouselIndex(index);
        startTransition()
        let awaitTransition = () => setTimeout(stopTransition, 1000);
        awaitTransition()
    }

    const Circle = () => {
        return (
            <div className={classes.circle}>

            </div>
        )
    }

    const CircleOutlined = (props) => {
        return (
            <div className={classes.circleOutlined} onClick={() => circleSelect(props.index)}>

            </div>
        )
    }

    const startTransition = () => {
        document.getElementById("announcement").classList.add(classes.annAnimation);
        document.getElementById("ann-subtext").classList.add(classes.annAnimation);
    }

    const stopTransition = () => {
        document.getElementById("announcement").classList.remove(classes.annAnimation);
        document.getElementById("ann-subtext").classList.remove(classes.annAnimation)
    }

    const carouselRight = () => {
        if (carouselIndex === (announcements.length - 1)) {
            setCarouselIndex(0);
        } else {
            setCarouselIndex(carouselIndex + 1);
        }
    }

    const carouselLeft = () => {
        if (carouselIndex === 0) {
            setCarouselIndex(announcements.length - 1)
        } else {
            setCarouselIndex(carouselIndex - 1);
        }
    }

    const countLineBreaks = (announcement) => {

        if (!announcement) return

        let regex = /(<br>)|(<p>)/g
        let lineBreaks = announcement.match(regex);

        if (lineBreaks !== null && lineBreaks.length > 1) {
            return lineBreaks.length
        } else {
            return 0
        }
    }

    const countCharacters = (announcement) => {
        if (!announcement) return

        let regex = /<br>/g
        let stringArray = announcement.split(regex)
        let stringsWithoutTags = []

        stringArray.forEach(item => {
            let string = item.replace(/(<([/a-zA-Z01-9]*)>)/g, "")
            stringsWithoutTags.push(string)
        })

        return stringsWithoutTags.join(" ").length;
    }

    const toggleReadOption = () => {
        setAutoCarousel(false)

        if (readOption === "... Read More") {
            setReadOption("Read Less")
        } else {
            setReadOption("... Read More")
        }
    }

    if (!announcements || announcements.length === 0) {
        return (<></>)
    }

    return (
        <Card title="Announcements" classes={{ content: classes.contentWrapper }} theme="tertiary" id="announcement">
            <div className={classes.annWrap} id="annWrap" onClick={(() => setAutoCarousel(false))}>
                <div className={classes.annContent}>
                    <div id="ann-body" className={`${classes.annBody} `}>
                        <HtmlParser readOption={readOption} isAnnouncement={true} announcementHistoryList={false}>
                            {
                                announcements[carouselIndex].body
                            }
                        </HtmlParser>
                        {
                            readOption !== null && !isSmallScreen &&
                            <span id="toggle-read-option" onClick={() => toggleReadOption()} style={{ bottom: readOption === "... Read More" ? "13px" : "18px" }}>{readOption}</span>
                        }
                        {
                            readOption !== null && isSmallScreen &&
                            <span id="toggle-read-option" onClick={() => toggleReadOption()} style={{ bottom: readOption === "... Read More" ? "18px" : "8px" }}>{readOption}</span>
                        }
                    </div>
                    <div className={classes.annNav} onClick={(() => setAutoCarousel(false))}>
                        <div id="ann-subtext" className={classes.annSubtext}>
                            <p>{announcementSubtext(announcements[carouselIndex], formValues)}</p>
                        </div>
                        {
                            announcements.length > 1 &&
                            <div className={`${classes.carouselNav} ${classes.iconSize}`}>
                                <div className={classes.leftArrow}>
                                    <LeftArrow onClick={(() => { carouselLeft(); setAutoCarousel(false) })} classes={{ root: classes.iconSize }} disabled={carouselIndex === 0} id="left-arrow-announcements">
                                    </LeftArrow>
                                </div>
                                <div className={classes.circleGroup}>
                                    {
                                        announcements.map((announcement, index) => index === carouselIndex ? <Circle id="circle" fontSize="inherit" key={index} onClick={(() => setAutoCarousel(false))} /> : <CircleOutlined id="circleOutlined" index={index} fontSize="inherit" key={index} onClick={(() => setAutoCarousel(false))} />)
                                    }
                                </div>
                                <div className={classes.rightArrow}>
                                    <RightArrow onClick={(() => { carouselRight(); setAutoCarousel(false) })} classes={{ root: classes.iconSize }} disabled={carouselIndex > announcements.length - 2} id="right-arrow-announcements">
                                    </RightArrow>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </Card>
    )
}