import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import { CalendarEventsContext } from '.';
import { getUrlSearchParam } from '../../../../helpers/utils';
import EachEventButton from './EachEventButton';
import EachEventHeader from './EachEventHeader';
import { useStyles } from './styles';

import {ReactComponent as ExternalLink} from "../../../../icons/external-link.svg"
const externalLinkStyle = {
    'position': 'relative',
    'top': '2px',
    'width': '15px',
    'height': '15px'
}

const evenTypes=require('./eventTypes.json');

export default function EachEvent({
    eventItem=null
}) {

    const classes = useStyles();

    const [eventState,setEventState]=useState(null);

    const {eventStateRefreshRate}=useContext(CalendarEventsContext);

    //** Event Type defines the type of event per th title or by the calendar name */
    const eventType = (getUrlSearchParam(eventItem.calendar_url,"src",) === 'kenzie.academy_4lbsmcqfu87of4dunpr64pcuao@group.calendar.google.com' ?
    evenTypes.find(i => i.id === 'event') :
    evenTypes.find(i => i.id === 'class'));

    const checkEventState = () => {

        let sd = moment(eventItem.start_date_time);
        let ed = moment(eventItem.end_date_time);
        let td = moment();

        //1. check if event is live. check with moment 
        //Checkout moment query docs: https://momentjs.com/docs/#/query/
        if (td.isSameOrAfter(sd) && td.isSameOrBefore(ed)) {
            setEventState("is_live");
            return;

        } else if (td.isAfter(ed)) { //2. Check if event is passed
            setEventState("is_passed");
            return;

        } else if (sd.diff(td, 'minutes') <= 10 && sd.diff(td, 'minutes') >= 0) {//checks if event is withing 10 mins timeframe 
            setEventState("is_join_early");
            return;

        } else if (td.isBefore(sd)) {//check if event is in future
            setEventState("is_future");
            return;
        }

    }

    useEffect(() => {

        checkEventState();//Initial execution to check state. After initial exec, this method runs every 1second

        const interval = setInterval(checkEventState, eventStateRefreshRate);

        return(()=>{
            clearInterval(interval);
        })

    }, []);

    /** Get the timestamp format. If minutes is zero, dont display the minutes, otherwise display the minutes*/
    const getTimeStamp=(val)=>{
        let d=moment(val);

        if(d.minutes()===0){
            return d.format('h A')
        }

        return d.format('h:mm A');
    }

    return (
        <div className={classes.eachEventOuterContainer}>
            {
                eventState==="is_live" && <div className='v-align happening-now-indicator'></div>
            }
            <div className={`${classes[`${eventType.id}Border`]} ${classes[`${eventType.id}BgColor`]} ${classes.eachEventContainer}`}>
            <div className='each-event-inner-container'>
                <div className='each-event-details'>
                   <EachEventHeader 
                        eventType={eventType}
                        eventState={eventState}
                        eventItem={eventItem} />
                    {eventItem?.event_url ?
                        <a className="event-link" href={eventItem.event_url} target="_blank" rel="noreferrer">
                            <div className='event-summary'>{eventItem.summary} <ExternalLink style={externalLinkStyle}/></div>
                        </a> :
                        <div className='event-summary'>{eventItem.summary}</div>
                    }
                    {
                        eventType.id==="event" && <div className='event-calendar-name'>Kenzie Speakers & Events</div>
                    }
                    <div className='event-timestamp'>{getTimeStamp(eventItem.start_date_time)} - {getTimeStamp(eventItem.end_date_time)}</div>
                </div>
                <div className='each-eventItem-action-button'>
                    <EachEventButton 
                        eventType={eventType}
                        eventState={(eventItem?.meeting_url && eventItem.meeting_url.includes("zoom.us")) ? eventState : "no_zoom"}
                        eventItem={eventItem}
                    />
                </div>
            </div>
        </div>
        </div>
        
    )
}