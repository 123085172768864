import React from 'react';
import useStyles from './styles';
import skeletonStyles from './SkeletonStyles'
import { Skeleton, Container } from '@mui/material';

const ConnectSkeleton = () => {
    const classes = useStyles();
    const skeletonClass = skeletonStyles();
    return (
        <div className={classes.directoryWrapper} >
            <Container sx={{marginBottom: "80px", width: "100vw"}}>
                <div style={{ "maxWidth": "400px" }}>
                    <Skeleton variant="rectangular" animatin="wave" width={405} height={180} sx={{borderRadius: "10px", marginTop: "30px"}}/>
                </div>
                <div className={skeletonClass.cohortInfoAndFilters}>
                    <div className={skeletonClass.directoryCohort}>
                        <p><Skeleton variant='text' animation="wave" width={250} height={40}/></p>
                    </div>
                    <div className={skeletonClass.filters}>
                        <div className={skeletonClass.filterSelect}>
                            <Skeleton variant='circular' animation="wave" width={120} height={36} sx={{borderRadius: "100px", marginRight: "15px"}} />
                            <Skeleton variant='circular' animation="wave" width={110} height={36} sx={{borderRadius: "100px", marginRight: "15px"}} />
                        </div>
                        <div className={skeletonClass.searchBar}>
                            <Skeleton variant='rectangular' animation="wave" width={326} height={36} sx={{borderRadius: "8px"}}/>
                        </div>
                    </div>
                </div>
                <div className={skeletonClass.skeletonCards}>
                    {
                        Array.from(new Array(15)).map(() => (
                            <Skeleton variant="rectangular" animation="wave" width={210} height={280} sx={{borderRadius: "8px"}}/>
                        ))
                    }
                </div>
            </Container>
        </div>
    )
}

export default ConnectSkeleton