import { createContext, useContext, useEffect, useState } from "react";
import { getCalendarInfoByCohortId, getVideosByGoogleCalendarId, getWebtoolsByCohortId, getSupportRequests, updateCohort, manageCohortWebtools, postCalendar, updateCourse, createCourses } from "../../api";
import { editCalendar, getAnnouncements } from "../../api";

import { getCohortInfo, getPrograms } from "../pages/v2/apiV2";
import useLocalStorage from '../../sharedComponents/hooks/useLocalStorage';
import { mapCohortMembersForMemberManagementPage } from "../pages/adminSettings/learnersManagement/helpers";
import { ROLE_MAPPINGS, WEBTOOL_MAPPINGS, PROGRAMS, userIsAdmin } from "../constants";
import { getInActiveCohorts, getActiveCohorts } from "./helpersV2";


import { ACPDataContext } from "./ACPDataContext";
const ACPDataContextV2 = createContext()

const ACPDataContextV2Provider = ({ children, appData }) => {
    /** NEW ACP DATA STORE
     * Use this store to make initial api GET calls
     * In the future, will also make PUT and POST calls here
     * */

    const legacyDataStore = useContext(ACPDataContext);//legacy cohort IDs for quick Announcement fix
    const LEGACY_setSelectedCohortIds = legacyDataStore.setSelectedCohortIds
    const [acpDataLoading, setACPDataLoading] = useState(true)
    const [cohort, setCohort] = useState({})
    const [supportRequests, setSupportRequests] = useState([])
    const [members, setMembers] = useState([])
    const [videos, setVideos] = useState([])
    const [webtools, setWebtools] = useState([])
    const [calendarInfo, setCalendarInfo] = useState({})
    const [courses, setCourses] = useState([])
    const [courseAssociations, setCourseAssociations] = useState([]) // Tracked seperatly from courses to not mistake a change that isn't saved
    const [cohortAnnouncements, setCohortAnnouncements] = useState([])
    const [everyoneAnnouncements, setEveryoneAnnouncements] = useState([])
    const [adminPrograms, setAdminPrograms] = useState({})
    const userCourses = appData?.userData?.mk_user_courses

    const userAdminCourses = appData?.userData?.mk_user_courses?.filter(c => userIsAdmin(c))
    const defaultCohort = userAdminCourses?.length ? userAdminCourses[0] : userCourses?.length ? userCourses[0] : null

    const [selectedCohortId, setSelectedCohortId] = useLocalStorage('mk_acp_selected-cohort-id', defaultCohort?.cohort_id || null)








    const fetchCohortAnnouncements = async () => {
        const announcements = await getAnnouncements({
            "cohort_id": selectedCohortId,
            'expand': 'cohorts,countusers,learnersOnly'
        });

        setCohortAnnouncements(announcements)
        return announcements
    }
    const fetchEveryoneAnnouncements = async () => {
        const announcements = await getAnnouncements({
            'audience': 1,
            'expand': 'cohorts,countusers,learnersOnly'
        });

        setEveryoneAnnouncements(announcements)
        return announcements
    }



    const fetchAndSetAdminData = async () => {

        const programs = await getPrograms({
            "expand": "cohorts,courses"
        });
        setAdminPrograms(structureAdminPrograms(programs))

    }

    const fetchAndSetMembers = async () => {
        const data = await fetchCohortAndMembersData()
        setMembers(data.members)
        setCohort(data.cohort)
        setCourses(data.courses)
        return data
    }

    const fetchCohortAndMembersData = async () => {
        let cohort = await getCohortInfo(selectedCohortId)
        cohort = structureCohortObject(cohort)
        const members = structureMemberObject(cohort?.mk_user_courses)
        const courses = structureCoursesObject(cohort?.program_mappings?.map(c => ({ ...c, ...c.course, cohort_id: c.cohort_id, is_active: c.is_active })))
        delete cohort.mk_user_courses
        return { cohort: cohort, members: members, courses }

    }


    const fetchSupportRequests = async () => {
        const reqs = await getSupportRequests(selectedCohortId)
        return structureSupportRequestsObject(reqs)

    }

    const fetchCalendarAndVideos = async () => {
        const calendarRes = await getCalendarInfoByCohortId(selectedCohortId)
        let videosRes = []
        if (calendarRes[0]?.google_calendar_id) {
            videosRes = await getVideosByGoogleCalendarId(calendarRes[0]?.google_calendar_id)
        }
        return ({
            ...structureVideoObject(videosRes),
            calendarInfo: calendarRes[0] || {}
        })


    }

    const fetchWebtools = async () => {
        const webtoolsRes = await getWebtoolsByCohortId(selectedCohortId)
        return ({ webtools: structureWebtoolsObject(webtoolsRes) })

    }
    const fetchDashboardData = async () => {
        //make all fetch calls and return all data in a a single object
        //since these functions do not rely on data from other fetch calls, we can resolve all of them at once to save time loading
        const [cohortData, supportReqData, calVideoData, webtoolsData] = await Promise.all(
            [fetchCohortAndMembersData(), fetchSupportRequests(), fetchCalendarAndVideos(), fetchWebtools()])


        return ({
            ...cohortData, //cohort:{}, members:{}, courses:[]
            ...supportReqData, //supportRequests:{}
            ...calVideoData,//videos:[], calendarInfo: {}
            ...webtoolsData //webtools:[]
        })
    }



    const updateSingleMember = (member) => {
        //Legacy code already calls PUT request, so only set displayed Data here
        const oldMembers = [...members]
        const oldMember = oldMembers.find(m => m.email === member.email)
        const oldIndex = oldMembers.indexOf(oldMember)
        oldMembers.splice(oldIndex, 1, { ...oldMember, ...member })
        const result = structureMemberObject(oldMembers)
        setMembers(result)
    }


    const updateSingleVideo = (newVideo) => {
        //Legacy code already calls PUT request, so only set displayed Data here
        const newVideos = [...videos.all]
        const oldVideo = newVideos.find((e) => e.uuid === newVideo.uuid)
        const foundIndex = newVideos.indexOf(oldVideo)
        newVideos.splice(foundIndex, 1, newVideo)
        const result = structureVideoObject(newVideos)

        setVideos(result.videos)
    }
    async function updateCalendar(calendar, calendarInfo, cohortId, cohortName) {
        const result = { name: 'Calendar', didUpdate: true }
        let response;
        let payload;
        if (!calendar?.calendar_url) {
            calendar.calendar_url = calendarInfo?.calendar_url
        }
        if (!calendar?.google_calendar_id) {
            calendar.google_calendar_id = calendarInfo?.google_calendar_id
        }
        if (!Object.keys(calendarInfo).length) {

            payload = { ...calendar, active: true, calendar_name: `${cohortName} Calendar` }
            response = await postCalendar(cohortId, payload)
            if (response?.message?.calendar) {
                setCalendarInfo(payload)
            }
        }
        else {
            payload = { ...calendarInfo, ...calendar }
            response = await editCalendar(cohortId, calendarInfo.id, payload)
            if (response) {

                setCalendarInfo(payload)
            }
        }

        return response ? result : { ...result, didUpdate: false }


    }
    async function updateSingleCohort(initialCohort, cohort, cohortId) {
        const result = { name: 'Cohort', didUpdate: true }
        let updates = Object.keys(cohort) // Remove null changes
            .filter((key) => cohort[key] !== "")
            .reduce((a, key) => ({ ...a, [key]: cohort[key] }), {});
        const response = await updateCohort(updates, cohortId)

        if (response?.message?.cohort?.length) {
            setCohort(structureCohortObject({ ...initialCohort, ...updates }))

        }
        return response ? result : { ...result, didUpdate: false }

    }

    async function updateWebtools(webtoolUpdates, cohortId) {
        const result = { name: 'Webtools', didUpdate: true }

        if (webtoolUpdates.length) {
            const response = await manageCohortWebtools(cohortId, webtoolUpdates)
            if (response?.length) {
                if (response.length === WEBTOOL_MAPPINGS.length) {//if its a new cohort where new rows are being created for each webtool
                    const toolResponse = await fetchWebtools()
                    setWebtools(structureWebtoolsObject(toolResponse.webtools))
                }
                else {
                    let webtoolsToUpdate = [...webtools]
                    for (let t of response) {
                        const foundIndex = webtoolsToUpdate.findIndex(w => Number(w?.webtools_id) === Number(t.webtools_id))
                        const updateVal = webtoolUpdates.find(w => Number(w?.webtools_id) === Number(t.webtools_id))
                        if (foundIndex !== -1 && updateVal) {

                            webtoolsToUpdate[foundIndex].is_active = updateVal.is_active
                        }
                        else if (foundIndex === -1) {//its a new tool, re-fetch
                            const toolResponse = await fetchWebtools()
                            webtoolsToUpdate = toolResponse.webtools
                        }
                    }

                    setWebtools(structureWebtoolsObject(webtoolsToUpdate))
                }
            }

            return result
        }
        return { ...result, didUpdate: false, message: 'Failed To Update Webtools.' }

    }

    async function addCourses(courseAdditions) {
        const email = userCourses[0].user_email
        const program_id = cohort["program_mappings"][0]["program_id"]
        const result = { name: "Courses - additions", didUpdate: true }
        if (courseAdditions.length) {
            let payload = courseAdditions.map(course => ({
                cohort_id: selectedCohortId,
                coursename: course.coursename,
                lms_course_id: course.lms_course_id,
                subscribed_canvas: course.subscribed_canvas,
                program_id,
                created_by: email
            }))
            const response = await createCourses(payload)
            if (response?.data?.message?.courses?.length) {
                setCourses({ ...courses, active: [...courses["active"], ...response.data.message.courses] })
                return result
            }
        }
        return { ...result, didUpdate: false, message: 'Failed to make course additions' }
    }

    async function removeCourses(courseDeletions) {
        const email = userCourses[0].user_email
        const result = { name: "Courses - deletions", didUpdate: true }
        let active = [...courses["active"]]
        for (let course of courseDeletions) {
            let values = { cohort_id: selectedCohortId, is_active: false, updated_by: email }
            const response = await updateCourse(values, course.lms_course_id)

            if (!response?.message?.course) {
                return { ...result, didUpdate: false, message: 'Failed to remove courses' }
            }
            let index = active.findIndex(i => i.lms_course_id === course.lms_course_id)
            if (index !== -1)  active.splice(index, 1)
        }
        setCourses(structureNewCoursesObject(courses, active))
        return result
    }

    useEffect(() => {
        //fetch and set everything when selected cohort id changes
        setACPDataLoading(true)
        if (selectedCohortId) {
            LEGACY_setSelectedCohortIds([selectedCohortId])//set selected cohort id for legacy data store, being used for announcements
            try {
                fetchDashboardData().then((data) => {

                    setCohort(data.cohort)
                    setMembers(data.members)
                    setSupportRequests(data.supportRequests)
                    setVideos(data.videos)
                    setCalendarInfo(data.calendarInfo)
                    setCourses(data.courses)
                    setCourseAssociations(data.courses)
                    setWebtools(data.webtools)
                    setACPDataLoading(false)

                })
            }
            catch (e) {
                console.error(e)
                setACPDataLoading(false)

            }

        }

    }, [selectedCohortId])


    useEffect(() => {
        //fetch and set everything when selected cohort id changes
        if (window.location.pathname.includes('acp/admin/settings'))
            setACPDataLoading(true)
        try {
            fetchAndSetAdminData()
            if (window.location.pathname.includes('acp/admin/settings'))
                setACPDataLoading(false)

        }
        catch (e) {
            console.error(e)
            if (window.location.pathname.includes('acp/admin/settings'))
                setACPDataLoading(false)
        }

    }, [])
    return (
        <ACPDataContextV2.Provider value={
            {
                ...appData,
                userIsCohortCoach: appData?.userData?.mk_user_courses?.find(c => c.cohort_id === selectedCohortId && c.user_role_id === 5),
                userAdminCourses: userAdminCourses,
                userCourses: appData?.userData?.mk_user_courses,
                hasCurrentCohortAccess: appData?.userData?.mk_user_courses?.find(c => c.cohort_id === selectedCohortId),
                hasCurrentCohortAdminAccess: userAdminCourses?.find(c => c.cohort_id === selectedCohortId),
                cohort,
                setCohort,
                updateSingleCohort,
                members,
                setMembers,
                supportRequests,
                setSupportRequests,
                videos,
                setVideos,
                calendarInfo,
                setCalendarInfo,
                updateCalendar,
                courses,
                courseAssociations,
                setCourseAssociations,
                webtools,
                setWebtools,
                updateWebtools,
                acpDataLoading,
                setACPDataLoading,
                fetchAndSetAdminData,
                selectedCohortId,
                setSelectedCohortId,
                fetchCohortAndMembersData,
                fetchSupportRequests,
                fetchAndSetMembers,
                updateSingleVideo,
                updateSingleMember,
                adminPrograms,
                addCourses,
                removeCourses,
                fetchCohortAnnouncements,
                cohortAnnouncements,
                setCohortAnnouncements,
                fetchEveryoneAnnouncements,
                setEveryoneAnnouncements,
                everyoneAnnouncements



            }
        }
        >
            {children}
        </ACPDataContextV2.Provider >
    )
}


function useACPDataContextV2() {
    return useContext(ACPDataContextV2)
}
export { useACPDataContextV2, ACPDataContextV2Provider };






/**
 STRUCTURE HELPER FUNCTIONS
 USE THESE WHEN SETTING STATE
 */
const structureMemberObject = (allMembers) => {
    if (!allMembers) return []

    allMembers = allMembers.map(m => (
        {
            ...m,
            ...m.user_email_mk_user,
            fullName: m.user_email_mk_user ? `${m.user_email_mk_user.first_name} ${m.user_email_mk_user.last_name}` : `${m.first_name} ${m.last_name}`,
            roleName: ROLE_MAPPINGS[m.user_role_id]
        }
    ))
    return mapCohortMembersForMemberManagementPage(allMembers)
}

const structureSupportRequestsObject = (reqs) => {
    return ({
        supportRequests: {
            all: reqs,
            awaitingReview: reqs.filter(r => r.status === "awaiting_review"),
            closed: reqs.filter(r => r.status === "closed")
        }
    })
}


const structureVideoObject = (videoArray) => {
    return ({
        videos: {
            all: videoArray,
            live: videoArray?.filter(v => !v.archived),
            archived: videoArray?.filter(v => v.archived),
            uncurated: videoArray.filter(v => v.updates === null),
            curated: videoArray.filter(v => v.updates !== null),
            mostRecent: videoArray?.sort((a, b) => {
                const aDate = new Date(a.video_details.recording_end)
                const bDate = new Date(b.video_details.recording_end)
                return aDate > bDate ? -1 : aDate < bDate ? 1 : 0
            })[0],

        },
    })
}

const structureCohortObject = (cohort) => {
    return {
        ...cohort,
        slackUrl: cohort?.slack_channel_id ? `https://app.slack.com/client/TCNHBFEG3/${cohort?.slack_channel_id}` : null,
        inscribeUrl: cohort?.inscribe_community_id ? `https://inscribe.education/main/kenzie/${cohort?.inscribe_community_id}/home` : null

    }
}

const structureWebtoolsObject = (webtools) => {
    webtools.sort((a, b) => a.webtool.name.localeCompare(b.webtool.name))
    return webtools
}
const structureNewCoursesObject = (courses, newActive) => {
    let primary = {}
    let active = [...newActive, ...courses.active]
    if (active.length)
        primary = {
            ...active[0],
            // slackUrl: active[0]?.slack_channel_id ? `https://app.slack.com/client/TCNHBFEG3/${active[0]?.slack_channel_id}` : null,
            // inscribeUrl: active[0]?.inscribe_community_id ? `https://inscribe.education/main/kenzie/${active[0]?.inscribe_community_id}/home` : null
        }
    return ({
        ...courses,
        active,
        primary
    })
}
const structureCoursesObject = (courses) => {
    const active = courses?.filter(c => c.is_active && c.course_id)
    let primary = null
    if (active?.length)
        primary = {
            ...active[0],
            // slackUrl: active[0]?.slack_channel_id ? `https://app.slack.com/client/TCNHBFEG3/${active[0]?.slack_channel_id}` : null,
            // inscribeUrl: active[0]?.inscribe_community_id ? `https://inscribe.education/main/kenzie/${active[0]?.inscribe_community_id}/home` : null
        }
    return ({
        all: courses,
        active: active,
        inactive: courses?.filter(c => !c.is_active),
        primary: { ...primary }
    }

    )

}


const structureAdminPrograms = (programs) => {
    const allPrograms = []
    const activePrograms = []
    for (let program of programs) {
        const structured = {
            ...program,
            cohorts: {
                active: getActiveCohorts(program),
                inactive: getInActiveCohorts(program)
            }

        }
        allPrograms.push(structured)
        if (PROGRAMS.find(p => p.id === program.id)) {
            activePrograms.push(structured)
        }
    }
    return ({

        all: allPrograms,
        active: activePrograms
    }
    )



}