
import { Tab } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
const LinkTab = props => {
    return (
        <Link style={{ textDecoration: "none" }} to={props.to}>
            <Tab
                component="span"
                {...props}
            />
        </Link>
    );
}
export default LinkTab