import { makeStyles } from "@mui/styles";

const skeletonStyles = makeStyles((theme) => ({
    cohortInfoAndFilters: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            alignItems: "flex-start"
        }
    },
    directoryCohort: {
        [theme.breakpoints.down(950)]: {
            display: "flex",
            justifyContent: "center",
        }
    },
    filters: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
        }
    },
    filterSelect: {
        display: "flex",
        [theme.breakpoints.down(950)]: {
            marginBottom: "25px"
        }
    },
    skeletonCards: {
        marginTop: "30px",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "border-box",
        rowGap: "15px",
        columnGap: "20px"
    },
    searchBar: {
        marginRight: "20px"
    }
}))

export default skeletonStyles;