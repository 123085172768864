import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	BrowserRouter, Route, Switch
} from "react-router-dom";
import XMLParser from 'react-xml-parser'
import './App.css';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Provider as AlertProvider } from 'react-alert'
import CustomAlertTemplate from './acp/components/CustomAlertTemplate';

import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuid4 } from 'uuid';

//Access Denied Pages
import AccessDeniedAfterAuth from './sharedComponents/AccessDeniedAfterAuth';
import AccessDeniedAuthError from './sharedComponents/AccessDeniedAuthError';
import AccessDeniedForPage from './sharedComponents/AccessDeniedForPage';

//Sub Routers
import LearnersRouter from './learner/router/LearnersRouter';

//Shared Components
import { addNavigatorOnlineEventHandlers, attachLogoutEventHandler, windowCounter } from './helpers/appLevelEventHelpers';
// import history from "./helpers/history";
import MyKenzieLoader from './sharedComponents/MykenzieLoader';
import UserConfirmationOnLeave from './sharedComponents/UserConfirmationOnLeave';

import { getUsers } from './api/userManagement';

//Contexts
import { AppDataContext } from './context';
import { LearnersDataStore } from './learner/context/LearnersDataStore';
import { ACPDataStore } from './acp/context/ACPDataStore';

import { ACPDataContextV2Provider } from './acp/context/ACPDataStoreV2';

//Helpers
import startFullStorySession from './helpers/startFullStorySession';
import {
	checkIfUserIsAdmin,
	checkIfUserIsCoach, checkIfUserIsStudent, getCoachCoursesPerApp, getCoursesPerRole
} from './helpers/userRolesHelper';

//Roles
import userRoles from './helpers/userRoles';

import LandingPage from './pages/landingPage/index';
import ACPRouter from './acp/router/ACPRouter'

const MAINTENANCE = false
let initialLoadFlag = false;

function App() {
	if (MAINTENANCE) {
		window.location.href = "https://kenzie.statuspage.io/"
	}
	let { isAuthenticated, isLoading, error, user, logout } = useAuth0()
	const [hasNoAccessToCourses, setHasNoAccessToCourses] = useState(null);
	const [hasAdminAccess, setHasAdminAccess] = useState(null);
	const [userRole, setUserRole] = useState(null);

	const [isUserDataFetched, setIsUserDataFetched] = useState(false);
	const [isAllComponentsLoaded, setIsAllComponentsLoaded] = useState(false);

	const [acpDataLoaded, setACPDataLoaded] = useState(false);

	const [openUserConfirmationOnLeave, setOpenUserConfirmationOnLeave] = useState(true);

	const [userData, setUserData] = useState(null);

	const [gtmUserId, setGtmUserId] = useState(null)
	
	let eventSubscriptions = useRef({
		onLineEventHandlerSubscription: false,
		logoutEventSubscription: false,
		windowCounter: false
	})

	/**
	 * Clean up
	*/
	useEffect(() => {
		fetchLtiConfig()
		fetchLtiIconUrl()
		return (() => {
			setIsUserDataFetched(false);
			setIsAllComponentsLoaded(false);
			setUserData(null);
		});
	}, []);

	useEffect(() => {
		// Assign user a personalized ID after successful login. (Google Tag Manager)
		if(userRole !== null) {
			setGtmUserId(
				userRole?.id === 1 ? 

				`kl-${uuid4()}` 
				: userRole?.id === 5 ? 
				`kc-${uuid4()}` 
				: 
				`ks-${uuid4()}`
			)
		}
	}, [userRole])
	
	useEffect(() => {
		if (user && user?.email && !initialLoadFlag) {
			
			initialLoadFlag = true;

			getUserInformation().then(userInfo => {

				//;
				//If user is not assigned to any courses 
				if ((userInfo.mk_user_courses.length === 0)) {
					//Future Implementation: Automatically send email to developer@mykenzie with info
					setHasNoAccessToCourses(true);

					//Is user a coach
				} else if (userInfo.mk_user_courses.length > 0 && checkIfUserIsCoach(userInfo)) {
					// This function checks if the localstorage is set to "switch to ACP" = true, 
					// if yes get courses where coach is assigned as coach
					// Please see the logic explanation in getCoachCoursesPerApp function
					let getCoachCourses = getCoachCoursesPerApp(userInfo);

					if (getCoachCourses.length === 0) {
						setHasNoAccessToCourses(true);
						return;

					} else if (getCoachCourses.length > 0) {
						setUserRole(userRoles.find(i => i.id === 5));//Get user role information and permissions
						userInfo.mk_user_courses = getCoachCourses;//Assigining coarses that coach is assigned to
					}

					//Is user a admin
				} else if (userInfo.mk_user_courses.length > 0 && !checkIfUserIsCoach(userInfo) && checkIfUserIsAdmin(user)) {

					if (userInfo.mk_user_courses.find(i => i.user_role_id === 11)) {
						setUserRole(userRoles.find(i => i.id === 11));
						setHasNoAccessToCourses(false);
						setHasAdminAccess(true);

					} else {
						setUserRole(userRoles.find(i => [9,16,17].includes(i.id)));//anyone other than coach and student are considered to be an admin for now
						setHasNoAccessToCourses(false);
						setHasAdminAccess(true);
					}

				} else if (userInfo.mk_user_courses.length > 0) {//User is student if none of the above

					userInfo.mk_user_courses = getCoursesPerRole(userInfo, 1);
					setUserRole(userRoles.find(i => i.id === 1))//Get student permission scheme and role information
					setHasNoAccessToCourses(!userInfo.mk_user_courses.length > 0);
					setHasAdminAccess(false);
				}

				setUserData(userInfo);

			}).catch(err => {
				//If any error while fetching the information. Just send no Access
				//This will be replaced 
				console.error(err);
				setHasNoAccessToCourses(true);
			});
		}
	}, [user]);

	/**
	 * Get Initial user information
	 */
	const getUserInformation = useCallback(async () => {
		let controller = new AbortController();
		try {
			if (user && user?.email) {

				//;
				//Execute initUser - method is deprecated .
				//Instead user /get/user - see readme file for more details
				let userInfo = await getUsers({
					username: user.email,
					preferred_name: user.preferred_name,
					expand: "courses,cohorts,programs",
					//attributes for role_id to identify admin and limited admin roles
					// attributes:"first_name,courses/user_role_id"

				});

				//;

				return userInfo.pop();//Get the first element of the userdata since there should be only one user with the email id
			}
		} catch (error) {
			console.error(error, "App.js, getUserInformation");
			setHasNoAccessToCourses(true);
		}
		return () => controller?.abort();

	}, [user]);


	/**
	 * After user info is fetched from our DB
	 * check if all components are loaded 
	 */
	useEffect(() => {
		if (userData !== null) {
			setIsUserDataFetched(true);

			// Starts FullStory session for learners and coaches
			if (checkIfUserIsStudent(userData) || checkIfUserIsCoach(userData)) {
				startFullStorySession(userData);
			}

			//Adds online navigator event handlers
			//If the Event is already subscribed. The event wont be subscribed again
			if (!eventSubscriptions.current.onLineEventHandlerSubscription) {
				addNavigatorOnlineEventHandlers(user);
				eventSubscriptions.current.onLineEventHandlerSubscription = true;
			}

			if (!eventSubscriptions.current.logoutEventSubscription) {
				//Attach logout event handler to window level
				attachLogoutEventHandler(user, logout);
				eventSubscriptions.current.logoutEventSubscription = true;
			}

			//Attaches the window counter trigger, if not already attached
			//Window counter counts the number of windows open for user for given domain
			if (!eventSubscriptions.current.windowCounter) {
				windowCounter();
				eventSubscriptions.current.windowCounter = true;
			}

		}
	}, [userData]);

	//Feedback loop from the componenets
	//This will hide the loader once all the components are loaded
	useEffect(() => {

		if ((/\/unauthorized/).test(window.location.pathname)) {
			setIsAllComponentsLoaded(true);

		} else if ((checkIfUserIsAdmin(user) || (userRole && userRole.id === 5 && localStorage.getItem("coachSwitchToACP") === "true")) && acpDataLoaded) {
			setIsAllComponentsLoaded(true);
		}

	}, [userData, userRole, acpDataLoaded]);

	useEffect(() => {
		if (isAllComponentsLoaded);
	}, [isAllComponentsLoaded])

	// Can be used for a component that will be unmounted when user data needs reset
	const fetchAndSetUserData = () => {
		getUserInformation().then(userInfo => setUserData(userInfo))
	}

	const fetchLtiConfig = () => {
		fetch("/lti/application.xml")
		.then(response => response.text())
		.then(xmlString => new XMLParser().parseFromString(xmlString))
		.then(data => console.log(data))
		.catch(error => console.log(error))
	}

	const fetchLtiIconUrl = () => {
		fetch("/favicon_io/favicon-16x16.png")
		.then(response => response.text())
		.then(xmlString => new XMLParser().parseFromString(xmlString))
		.then(data => console.log(data))
		.catch(error => console.log(error))
	}

	return (
		<>
			{
				//If not Authenticated reroutes to authO
				!isAuthenticated && !isLoading ?
					<LandingPage /> :

					//If there is an error in authentication OR doesnt have access to the page
					error ?
						<AccessDeniedAuthError /> :

						//If authenticated but there are no courses assigned to the user 
						user && isAuthenticated && hasNoAccessToCourses ?
							<AccessDeniedAfterAuth user={user}/> :

							// {App is till fetching user initial data and waiting for all the components to be loaded}
							isAuthenticated || isLoading ?
								<>
									<div style={isAllComponentsLoaded ? { display: "none" } : { display: "block" }}>
										<MyKenzieLoader
											message={""}
											user={userData && !userData["first_login"] ? userData : null}
											isAllComponentsLoaded={isAllComponentsLoaded} />
									</div>
									{
										isUserDataFetched && user ?
											<div style={!isAllComponentsLoaded ? { opacity: 0, height: 0, overflow: 'hidden' } : { opacity: 1 }}>
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<AppDataContext.Provider value={{
														"userData": { ...userData, hasAdminAccess },
														"setUserData": setUserData,//sets the user data at app level
														"fetchAndSetUserData": fetchAndSetUserData,
														"userRole": userRole,
														"acpDataLoaded": acpDataLoaded,
														"setACPDataLoaded": setACPDataLoaded,
														"setIsAllComponentsLoaded": setIsAllComponentsLoaded,
														"isAllComponentsLoaded": isAllComponentsLoaded,
														"gtmUserId": gtmUserId,
													}}>
														<BrowserRouter
															// history={history}
															getUserConfirmation={(message, callback) => {
																;
																return UserConfirmationOnLeave(message,
																	callback,
																	openUserConfirmationOnLeave,
																	setOpenUserConfirmationOnLeave);
															}}>
															<Switch>
																<Route path="/unauthorized">
																	<AccessDeniedForPage />
																</Route>

																{
																	hasAdminAccess || (userRole.id === 5 && localStorage.getItem("coachSwitchToACP") === "true") ?
																		<ACPDataStore>
																			<AlertProvider template={CustomAlertTemplate} containerStyle={{ zIndex: 999 }} position='middle' timeout={8000}>
																				{/* react-alert */}
																				<ACPDataContextV2Provider appData={{
																					setIsAllComponentsLoaded,
																					isAllComponentsLoaded,
																					"userData": { ...userData, hasAdminAccess },
																				}}>
																					<ACPRouter />
																				</ACPDataContextV2Provider>
																			</AlertProvider>
																		</ACPDataStore>
																		:
																		<LearnersDataStore>
																			<LearnersRouter />
																		</LearnersDataStore>
																}
															</Switch>

														</BrowserRouter>

													</AppDataContext.Provider>
												</LocalizationProvider>
												{/* <script>
													{
														(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
														new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
														j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
														})(window,document,'script','dataLayer','GTM-PSCDHPW')
													}
  												</script> */}
											</div> :
											null
									}
								</> :
								null
			}

		</>
	);
}

export default App;