
import { makeStyles } from "@mui/styles";

const useEditProfileStyles = makeStyles((theme) => (
    {
        /** Only for Edit Profile Page: Used in Both ACP Edit Profile and Learners Edit Profile */
        formSectionHeader: {
            color: "#1D3969",
            fontWeight: 500
        },
        formSection: {
            marginTop: "65px"
        },
        controlGroup: {
            marginTop: "25px"
        },
        controlSection: {
            marginTop: "30px"
        },
        control: {
            marginTop: "8px",
            "& label": {
                fontSize: "14px !important",
                fontFamily: "Raleway !important"
            },
            "& input[type='text']": {
                fontSize: "14px !important",
                fontFamily: "Raleway !important"
            },
            "& textarea": {
                fontSize: "14px !important",
                fontFamily: "Raleway !important"
            }
        },
        labels: {
            backgroundColor: 'white',
            paddingRight: "5px"
        },
        submitButton: {
            // margin: "100px 24px 100px 0px",

            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",

            [theme.breakpoints.down('md')]: {
                justifyContent: "center",
                // marginBottom: '25vh'
            },
            "& button": {
                width: '200px',
                color: 'white',
                backgroundColor: theme.colors.deepAqua,
                borderRadius: '50px',
                textTransform: 'capitalize',
                fontFamily: 'Nunito ',
                fontSize: '16px',
                '&:hover':{
                    color: 'white',
                    backgroundColor: theme.colors.deepAqua,
                },


                // ...theme.elements.darkBlueButton
            },
            "& .Mui-disabled": {
                backgroundColor: "rgba(0, 0, 0, 0.12)"
            }

        },
        fieldLabelText: {
            fontFamily: 'Nunito !important',
            fontWeight: 400,
            fontSize: '18px !important',
            lineHeight: '25px',
            margin: 0
        },
        focusTimeText: {
            fontFamily: 'Nunito !important',
            fontWeight: 500,
            fontSize: '20px !important',

            '& .note-text': {
                fontWeight: 600,
                fontSize: '12px'
            }
        },
        textArea: {
            width: "100% !important",
            fontFamily: "Raleway !important",
            border: '1.5px solid #14606B',
            borderRadius: '5px',
            padding: '10px',

            '& before': {
                border: 0
            }
        },
        growingInputText: {
            fontFamily: 'Nunito !important',
            fontWeight: 400,
            fontSize: '20px !important',
            lineHeight: '27.28px',
            '& input': {
                fontWeight: 700
            }
        },
        locationText: {
            fontFamily: 'Nunito !important',
            fontWeight: 400,
            fontSize: '20px !important',
            lineHeight: '27.28px',
            display: 'flex',
            alignItems: 'center',
            marginRight: '5px',
            '& input[type=text]': {
                fontFamily: 'Nunito !important',
                fontWeight: '700 !important',
                fontSize: '20px !important',
                lineHeight: '27.28px',
                border: 0
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'flex-start'
            },
        },
        radioContainer: {

            display: 'flex',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column'

            },


        }
    }
));

export default useEditProfileStyles;
