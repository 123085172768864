import React from 'react';



import CohortDropDownSingleSelect from './CohortDropDownSingleSelect';

import UserAvatar from './UserAvatar';
import { useACPDataContextV2 } from '../../context/ACPDataStoreV2';
import { NavLink } from 'react-router-dom'
import styled from "@emotion/styled"
import ACPLogo from '../../../images/acp-logo-2.png';

const navItems = [
    {
        path: '/',
        name: 'Overview'
    },
    {
        path: '/acp/listRequests/',
        name: 'Support Requests'
    },
    {
        path: '/acp/announcementHistory/',
        name: 'Announcements'
    },
    {
        path: '/acp/videoLibrary/',
        name: 'Video Library'
    },
    {
        path: '/acp/people',
        name: 'People'
    },
    {
        path: '/acp/admin/settings',
        name: 'Admin'
    },
]

const TopPane = () => {

    const { hasCurrentCohortAdminAccess } = useACPDataContextV2()



    /**
     * Cohort Drop Down Event Handlers - ACP Settings
     * @description - This method for acp settings single select cohort drop down
     * @param {*} event 
     */

    return (
        <NavContainer>
            <Logo src={ACPLogo} />
            <CohortDropDownSingleSelect />
            <LinkContainer>
                {navItems.map((item, i) => (
                    item.name === "Admin" ?
                        null
                        :
                        <StyledLink as={NavLink} key={i} to={item.path} isCurrent={(item.name === 'People' && window.location.pathname.startsWith('/acp/people')) || window.location.pathname === item.path}>{item.name}</StyledLink>
                )

                )}
            </LinkContainer>


            <AvatarContainer>

                {hasCurrentCohortAdminAccess
                    ?
                    <StyledLink id="admin-tab" as={NavLink} to={navItems.find((item) => item.name === "Admin").path} isCurrent={window.location.pathname === navItems.find((item) => item.name === "Admin").path}>{navItems.find((item) => item.name === "Admin").name}
                    </StyledLink>
                    :
                    null


                }

                <UserAvatar />
            </AvatarContainer>

        </NavContainer>
    )
}

export default TopPane;

const NavContainer = styled.header`
box-sizing: border-box;
height: 74px;
background-color: #1D3969;
width: 100vw;
display: flex;
align-items: center;
`
const Logo = styled.img`
width: 46px;
margin-left: 3vw;
margin-right: 3vw;
`

const LinkContainer = styled.nav`
margin-left: 2vw;
height: 100%;
width: 100%;
display: flex;
align-items: center;
/* justify-content: space-around; */
max-width: 800px;

`

const StyledLink = styled.div`

font-family: 'Nunito';
font-style: normal;
font-weight: ${props => props.isCurrent ? 700 : 500};
font-size: 16px;
line-height: 22px;
box-sizing: border-box;
text-decoration: none;
height: 100%;
text-align: center;
color: ${props => props.isCurrent ? '#1D3969' : '#fff'};
background-color: ${props => props.isCurrent ? '#F3F3F3' : 'initial'};
border-top: ${props => props.isCurrent ? '7.25px solid  #63C29D' : 0};
height: 100%;
display: flex;
justify-content: center;
align-items: center;
/* min-width: 150px; */
/* margin-left: 25px; */ 
padding: 0 16px;
border-radius: 4px 4px 0 0;
&#admin-tab{
    padding: 0px 20px;
    margin-right: 17px;
}
&:hover{
    background-color:${props => props.isCurrent ? '#F3F3F3' : '#445879'}
}
`

const AvatarContainer = styled.div`
box-sizing: border-box;
height: 100%;
display: flex;
align-items: center;
margin: 0 12px 0 auto;
padding: 0px 16px
`
