import { makeStyles } from "@mui/styles";

const useWorkLifeStyles = makeStyles((theme) => ({
    
    focusTimeDays: {
        display: "grid",
        flexWrap: "no-wrap",
        borderRadius:"8px",
        gridTemplateColumns: 'repeat(7, 14.28% [col-start])',
        border:"1px solid #5F5F5F",
        '& .each-day': {
            padding:"15px 10px 17px 10px",
            [theme.breakpoints.down('sm')]: {
                padding:"15px 0px 17px 0px !important",
            },
            textAlign:"center",
            borderRight:'1px solid #5F5F5F',
            textTransform:"capitalize",
            cursor:"pointer",
            position:"relative",
            '&:first-child':{
                borderTopLeftRadius:"8px",
                borderBottomLeftRadius:"8px"
            },
            '&:last-child':{
                borderRight:"0px",
                borderTopRightRadius:"8px",
                borderBottomRightRadius:"8px",
            },
            '& .entry-indicator':{
                width: "5px",
                height: "5px",
                backgroundColor: theme.colors.deepAqua,
                margin: "2px auto",
                borderRadius: "50%",
                position: "absolute",
                transform: "translateX(-50%)",
                left: "50%"
            }
        },
        '& .each-day-selected': {
            backgroundColor:theme.colors.lilac
        }
    },

    focusTimeContainer:{
        '& .focus-time-outer-container':{
            display: "grid",
            flexWrap: "wrap",
            columnGap:"15px",
            [theme.breakpoints.up('lg')]: {
                gridTemplateColumns:'repeat(4, 1fr [col-end])'
            },
            [theme.breakpoints.down('lg')]: {
                gridTemplateColumns:'repeat(4, 1fr [col-end])'
            },
            [theme.breakpoints.down('md')]: {
                gridTemplateColumns: 'repeat(4, 1fr [col-start])'
            },
            [theme.breakpoints.down('680')]: {
                gridTemplateColumns: 'repeat(2, 1fr [col-start])'
            }
        },
        '& .each-focus-time-container[data-slotid="all_day_albatross"]':{
            gridColumn: "1/5",
            [theme.breakpoints.down('680')]: {
                gridColumn: "1/3 !important",
            },
        },
        '& .each-focus-time-container':{
            cursor:"pointer",
            textAlign:"center",
            margin:"14px 0px"
        },
        '& .each-focus-time':{
            position:"relative",
            borderRadius:"8px",
            border: '4px solid transparent',
            backgroundColor:'white',
            padding:"15px 10px",
            fontWeight:500,
            '&:after':{
                content: '""',
                position: 'absolute',
                top: "-8px",
                bottom: "-8px",
                left: "-8px",
                right: "-8px",
                zIndex: -1,
                borderRadius: "12px",
            },
            '& .each-focus-time-img-container':{
                height:"50px",
                width: "100%",
                marginBottom:"10px",
                lineHeight: "50px",
                '& img':{
                    maxHeight: "100%",
                    maxWidth:"100%"
                }
            },
            '& .each-focus-time-header':{
                color:theme.colors.deepBlue
            },
            '& .each-focus-time-slot-name':{
                color:"grey"
            },
        },
        '& .each-focus-time[data-slotid="night_owl"]': {
            '&:after':{
                background: "linear-gradient(90deg, #02437C 0%, #0E89AC 100%)"
            }
        },
        '& .each-focus-time[data-slotid="morning_lark"]': {
            '&:after':{
                background: "linear-gradient(90deg, #0E88AC 0%, #97D8C6 100%)"
            }
        },
        '& .each-focus-time[data-slotid="afternoon_loon"]': {
            '&:after':{
                background: "linear-gradient(90deg, #94D7C6 0%, #F9BC6E 100%)"
            }
        },
        '& .each-focus-time[data-slotid="evening_eagle"]': {
            '&:after':{
                background: "linear-gradient(90deg, #F8BD70 0%, #243C7F 100%)"
            }
        },
        '& .each-focus-time[data-slotid="all_day_albatross"]': {
            width:"auto",
            '&:after':{
                background: "linear-gradient(90deg, #03457E 0%, #0E89AC 24.18%, #96D8C6 50%, #F8BD70 75.46%, #03457E 100%)"
            }
        },
        '& .each-focus-time-selected':{
            "backgroundColor":theme.colors.lilac,
            '&:after':{
                background:`linear-gradient(90deg, ${theme.colors.deepAqua} -19.6%, ${theme.colors.deepAqua} 33.6%) !important`,
            },
        },
    },

    workScheduleContainer:{
        display: "flex",
        justifyContent: "flex-start",
        alignContent:"center",
        alignItems: "center",
        flexDirection:"row",
        flexWrap:"wrap",
        boxSizing: "border-box",
        '& .each-work-schedule-button': {
            cursor:"pointer",
            margin: "0px 12px 15px 0px",
            height: "35px",
            width: "110px",
            fontSize: "12px",
            fontWeight: 600,
            border: "1px solid #000000",
            textAlign:"center",
            lineHeight:"35px",
            borderRadius: "100px"
        },
        '& .each-work-schedule-button-selected':{
            backgroundColor:theme.colors.lilac,
        }
    }
}))

export default useWorkLifeStyles;

