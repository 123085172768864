import moment from 'moment';
import { getCalendarEventRecordings } from '../../../../api/index';

/** Create date array starting from +/- 7 days from todays date */
export const createDateArray = () => {
    try {

        let sd=moment().startOf('day').subtract(7,'days');//Go back 7 days
        let ed=moment().startOf('day').add(7,'days');//Got forward 7 days

        let arr = [];

        while (sd.diff(ed, 'days') < 0) {
            arr.push(moment(sd));
            sd.add(1, 'days');
        }

        return arr;

    } catch (error) {
        console.error(error);
        throw error;
    }

}

/**
 * @description gets the list of events for the date value
 * @param {moment} momentDateValue 
 * @param {*} dataset 
 */
export const getEventsByDateValue = (momentDateValue, dataset) => {
    try {
        //Get all events where start date is same day 
        let evts=dataset.filter(i=>momentDateValue.diff(moment(i.start_date_time).startOf('day'),'days')===0);

        evts.sort((a,b)=>{
            return moment(a.start_date_time).startOf('day').isSameOrBefore(moment(b.start_date_time).startOf('day'))?-1:1
        });

        return evts;

    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * @description - Checks the state of the recording
 * //1. Queries all recordings and checks if recording available for the event 
 * //2. If no recording available and the event is more than 2 days old, recording_unavailable 
 * //3. If no recording available and the event is less than 2 days old, awaiting_recording 
 * @param {*} eventItem 
 * @param {*} eventRecordings 
 * @returns 
 */

const recordingCompareFn = (recording, meetingId, eventTime) =>{
    return (
        typeof recording !=='undefined' 
        && recording?.meeting_id 
        && meetingId 
        && parseInt(recording.meeting_id)===parseInt(meetingId) 
        && (moment(recording.video_details?.recording_start).format("MDY") === moment(eventTime).format("MDY")) 
        
    )

}

export const checkIfRecordingExists=(eventItem,eventRecordings)=>{


    try {

        let response={
            recordingDetails:[],
            state:null
        };

        if(eventItem.meeting_url!==null){

            let meetingId=getMeetingIdFromUrl(eventItem.meeting_url);
            let rec=eventRecordings!==null? eventRecordings.filter(e=> recordingCompareFn(e, meetingId, eventItem?.start_date_time)):[];
            if (rec?.length > 0 ){

                const sameName =  rec.filter(e=> e.title === eventItem.summary )
                if (sameName.length) rec = sameName
                response.state="has_recording";
                response.recordingDetails=rec
            }
            else if(!rec || rec.length===0){
                response.state="recording_unavailable";
            }

        }else{
            response.state="meetingId_unknown";
        }

        return response
        
    } catch (error) {
        console.error(error);
        return null;
    }
}

//Grab meeting ids from url 
export const getMeetingIdFromUrl=(url)=>{
    try {
        let uri=new URL(url);//converting to uri
        return uri?uri.pathname.match(/\d*$/)?.pop():null;
    } catch (error) {
        return null
    }
}

//Check from the 
export const fetchRecordingsForTodaysEndedSessions=async(dayEvents,eventRecordings)=>{
    try {
        let td=moment();

        let response=null;

        //Get the ended sesstions
        //Check if recording sessions exists for ended sessions
        //If recordings not available for ended sessions, fetch the recordings for those meeting Ids
        let meetingIds=dayEvents.reduce((acc,eventItem)=>{

            let isBefore=td.isSameOrAfter(moment(eventItem?.end_date_time));
            let recordingDetails=checkIfRecordingExists(eventItem, eventRecordings)?.recordingDetails;

            if(isBefore && recordingDetails.length===0 && eventItem?.meeting_url){
                let meetingId=getMeetingIdFromUrl(eventItem.meeting_url);
                if(meetingId!==null && meetingId.length>0) acc.push(meetingId)
            }
            return acc;
        },[]);

        //If meetingIds exists get the data from db 
        if (meetingIds && meetingIds.length > 0) {
            response=await getCalendarEventRecordings(meetingIds.join(','));
        }
        
        return response;

    } catch (error) {
        return null;
    }
}

export function filterDuplicateIds(arr) {
    const seenIds = new Set();
    return arr.filter(obj => {
      if (seenIds.has(obj.id)) {
        return false;
      } else {
        seenIds.add(obj.id);
        return true;
      }
    });
  }