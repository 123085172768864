import { memo } from "react"
import { useACPDataContextV2 } from "../../context/ACPDataStoreV2"
import styled from "@emotion/styled"
import { useStyles } from "./styles"
import CircularLoader from "../../../sharedComponents/CircularLoader"
import { Link } from 'react-router-dom'
// import { ReactComponent as QuestionMarkChatIcon } from '../../../icons/question-mark-chat-bubble.svg'
import { ReactComponent as ReleaseNotesIcon } from '../../../icons/release_notes_icon.svg'
import { ReactComponent as FolderIcon } from '../../../icons/folder_icon.svg'
import { ReactComponent as HelpdeskIcon } from '../../../icons/question_icon.svg'
import { formatRequestResponseTimeStr } from "./helpers"
import MKSettingsPanel from "./components/MKSettingsPanel"
import LatestVideoPanel from "./components/LatestVideoPanel"
import StudySessionPanel from "./components/StudySessionPanel"

import { filterMembers } from "../../context/helpersV2"

export default memo(function ACPHome() {
    const classes = useStyles();

    const {
        acpDataLoading,
        cohort,
        supportRequests,
        members,
        hasCurrentCohortAccess,
        selectedCohortId
    } = useACPDataContextV2()

    if (acpDataLoading) return <div style={{ height: '100%', display: 'flex' }}><CircularLoader sx={{ margin: 'auto' }} /></div>
    if (!selectedCohortId) return (
        <PageContainer>
            <PageHeaderContainer>
                <PageTitle>Select a Cohort From the Dropdown Menu Above</PageTitle>
            </PageHeaderContainer>
        </PageContainer>
    )
    if (!hasCurrentCohortAccess) return (
        <PageContainer>
            <PageHeaderContainer>
                <PageTitle>Select a Cohort From the Dropdown Menu Above</PageTitle>
            </PageHeaderContainer>
        </PageContainer>
    )
    return (
        <PageContainer>
            <PageHeaderContainer>

                <PageTitle>{cohort?.cohort_name}</PageTitle>

                <div className="role-container">

                    <p className="float-right">Learners</p>
                    <div className="role-count">{filterMembers(members, 'activeLearners').length}</div>

                    <p className="float-right">Admins</p>
                    <div className="role-count">{filterMembers(members, 'activeAdmins').length}</div>
                </div>
            </PageHeaderContainer>

            <DashboardContainer>

                <DashboardSection gridArea='sr'>

                    <SectionTitleContainer>
                        <SectionTitle>
                            Support Requests
                        </SectionTitle>
                    </SectionTitleContainer>
                    <SectionBody>
                        <FullWidthRow topRow>


                            <HeadingText style={{ marginRight: '15px' }}>
                                Support Requests Awaiting Review
                            </HeadingText>
                            <StyledButton as={Link} to="/acp/listRequests/" floatLeft>Triage Requests</StyledButton>
                        </FullWidthRow>
                        <div>

                            <SupportReqNumber>
                                {supportRequests?.awaitingReview?.length}
                            </SupportReqNumber>

                            <HeadingText style={{ marginTop: '12px' }}>Average Response Time</HeadingText>
                            <SupportReqNumber>
                                {formatRequestResponseTimeStr(supportRequests.closed)}
                            </SupportReqNumber>
                        </div>
                    </SectionBody>
                </DashboardSection>

                <StudySessionPanel />


                <LatestVideoPanel />


                <MKSettingsPanel />


                <RightOverviewPanel>
                    <DashboardSection gridArea='ppl' style={{ overflowY: 'auto' }}>
                        <SectionTitleContainer>
                            <SectionTitle>
                                People
                            </SectionTitle>
                        </SectionTitleContainer>
                        <SectionBody>


                            <StyledButton as={Link} to={'/acp/people/1'} floatLeft style={{ marginBottom: '15px', marginTop: '24px' }} >View Learner List</StyledButton>
                            <HeadingText style={{ marginTop: 'auto' }}>Support Team <b>({filterMembers(members, 'activeStaff').length})</b></HeadingText>
                            {!filterMembers(members, 'activeStaff').length ?
                                <BodyText>There are no Support Team members assigned to this cohort</BodyText>
                                :
                                null
                            }
                            {filterMembers(members, 'sortedActiveStaff').map((staff, i) => (
                                <FullWidthRow key={'staff' + i}>
                                    <BodyText >{staff.fullName}</BodyText>
                                    <BodyText className="float-right">{staff.roleName}</BodyText>
                                </FullWidthRow>
                            ))}


                            <HeadingText>Coaches <b>({filterMembers(members, 'activeCoaches').length})</b></HeadingText>
                            {!filterMembers(members, 'activeCoaches').length ?
                                <BodyText>There are no coaches assigned to this cohort</BodyText>
                                :
                                null
                            }

                            {filterMembers(members, 'activeCoaches').map((coach, i) => (
                                <FullWidthRow key={'coaches' + i}>
                                    <BodyText >{coach.fullName}</BodyText>
                                    <BodyText className="float-right">{coach.roleName}</BodyText>
                                </FullWidthRow>
                            ))}
                            <HeadingText>Admins <b>({filterMembers(members, 'activeAdmins').length})</b></HeadingText>
                            {!filterMembers(members, 'activeAdmins').length ?
                                <BodyText>There are no Admins assigned to this cohort</BodyText>
                                :
                                null
                            }
                            {filterMembers(members, 'activeAdmins').map((admin, i) => (
                                <FullWidthRow>
                                    <BodyText key={'admin' + i}>{admin.fullName}</BodyText>
                                    <BodyText className="float-right">{admin.roleName}</BodyText>
                                </FullWidthRow>
                            ))}
                        </SectionBody>
                    </DashboardSection>
                    <DashboardSection gridArea="res" style={{ overflowY: 'hidden' }}>
                        <div className={classes.assistance}>
                            <div className={classes.internalResources} onClick={() => window.open("https://docs.google.com/document/d/1zoZU8ARq7Q2YQuT3VoJHHJjzQ40Wr7-VdMj12PHIoLg/edit?usp=sharing", '_blank')}>
                                <div className={classes.resource}>
                                    <ReleaseNotesIcon />
                                    <span>Release Notes</span>
                                </div>
                                <div className={classes.resource} onClick={() => window.open("https://drive.google.com/drive/folders/1mT8j8YQEZZYVchfpc5SMeoaWmdFHudLZ?usp=share_link", '_blank')}>
                                    <FolderIcon />
                                    <span>Documentation</span>
                                </div>
                                <div className={classes.resource} onClick={() => window.open("https://kenzieacademy.atlassian.net/servicedesk/customer/portals", '_blank')}>
                                    <HelpdeskIcon />
                                    <span>Helpdesk</span>
                                </div>
                            </div>
                        </div>
                    </DashboardSection>
                </RightOverviewPanel>
                {/* REQUEST SECTION MAY BE USED IN FUTURE */}
                {/* <RequestSection>
                    <QuestionMarkChatIcon style={{ gridArea: 'icon', height: '25px', width: '25px' }} />
                    <p className="title">Need some assistance or have a suggestion?</p>
                    <p className="body">Please submit a tech request to the Kenzie R&D department. <br /> We will follow-up as soon as possible.</p>
                    <StyledButton gridArea='btn'>Submit Request</StyledButton>
                </RequestSection> */}
            </DashboardContainer>
        </PageContainer >
    )
})

export const FullWidthRow = styled.div`
display: flex;
width: 100%;
align-items: flex-start;
padding-top: ${props => props.topRow ? '24px' : 0};
 & h3 {
    margin-top: ${props => props.topRow ? '0' : '24px'};

 }
`

export const PageContainer = styled.main`
/* padding-left: 52px; */
padding-right: 52px;
padding-bottom: 5vh;
box-sizing: border-box;
`
export const DashboardContainer = styled.div`
display: grid;
grid-template-areas: 
"sr mks ppl"
"ss mks ppl"
"vid mks ppl";
grid-template-columns: 33.33% 33.33% 33.33%;
grid-column-gap: 22px;
grid-template-rows: 280px 280px 340px;
grid-row-gap: 21px;
`
export const RightOverviewPanel = styled.div`
display: grid;
grid-template-areas:
"ppl"
"ppl"
"res";
grid-template-columns: 100%;
grid-column-gap: 22px;
grid-template-rows: 280px 460px 160px;
grid-row-gap: 21px;
`

export const PageHeaderContainer = styled.div`
margin: 20px 0 20px 0;
display: flex;
align-items: center;
& .role-container{
    display: flex;
    align-items: center;
    margin-left: auto;
}
& .float-right{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    margin-left: 31px;
}
& .role-count{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 3px;
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    border-radius: 5px;
    width: 40px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
`
export const PageTitle = styled.h1`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 22px;

margin: 0;

`

export const DashboardSection = styled.div`
grid-area: ${props => props.gridArea};
border: 2px solid #C7C7C7;
border-radius: 10px;
box-sizing: border-box;
overflow-x: hidden;
overflow-y: auto;
background-color: ${props => props.gridArea === "res" ? `#F3F3F3;` : `#FFF;`}
position: relative;
`
export const SectionTitleContainer = styled.div`
display: flex;
align-items: center;
border-radius: 4px 4px 0 0 ;
background-color:  #445879;
height: 45px;
padding: 0 19px;
position: sticky;
top: 0;
z-index: 999;
`
export const SectionTitle = styled.h2`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: #ffffff;
margin: 0;
`


export const SectionBody = styled.div`
padding:  0 19px 20px 19px;
/* display: flex;
flex-direction: column;
align-items: flex-start; */
box-sizing: border-box;
max-height: calc(100% - 45px);
`

export const HeadingText = styled.h3`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
color: #1D3969;

margin-bottom: 8px;
margin-top: 20px;
`

export const BodyText = styled.p`
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
margin: 0;
margin-bottom: 12px;
margin-left: 6px;
box-sizing: border-box;
overflow-wrap: break-word;
&.float-right{
    margin-left: auto;
}
&.url{
    white-space: normal;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}
`
export const SettingsBodyText = styled(BodyText)`
opacity: .75;
`

export const SupportReqNumber = styled(BodyText)`
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 41px;
margin: 0;
margin-left: 8px;
`

export const RequestSection = styled.div`
grid-area: req;
background: #F3F3F3;
border: 2px solid #C7C7C7;
border-radius: 10px;
display: grid;
grid-template-areas:
"icon title title"
"body body btn";

grid-template-columns: 28px 1fr 150px;
grid-column-gap:  10px;
grid-template-rows: 30% 35px;
grid-row-gap: 16px;

padding: 15px 22px 0 22px;
/* padding-left: 22px; */
align-items: center;
& .title{
    grid-area: title;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}
& .body{
    grid-area: body;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}
`

export const StyledButton = styled.button`
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;
width: 150px;
box-sizing: border-box;
grid-area: ${props => props.gridArea ? props.gridArea : null};
text-decoration: none;
background: #FFFFFF;
border: 2px solid #404040;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
padding: 9px;
color: #1D3969;
cursor: pointer;
grid-area: ${props => props.gridArea ? props.gridArea : null};
margin-left: ${props => props.floatLeft ? 'auto' : null};
margin-top: ${props => props.floatDown ? 'auto' : null};
/* align-self: ${props => props.gridArea ? 'center' : null}; */
box-shadow:0px 4px 4px rgba(0, 0, 0, 0.15);
transition: all .300s;
&:hover{
    box-shadow: 2px 3px 6px #606060;
}
`