import { useState, useContext } from "react";
import { Modal } from "@mui/material";
import styled from "@emotion/styled";
import X from "../../../icons/x";
import img from "../../../images/survey-illistration.png"
import { AppDataContext } from "../../../context";
const SurveyModal = ({ onClose }) => {
    const { isAllComponentsLoaded } = useContext(AppDataContext)

    const [open, setOpen] = useState(true);
    const handleModalClose = () => {
        onClose()
        setOpen(false)
    }
    return (
        <Modal open={open && isAllComponentsLoaded} onClose={handleModalClose} disableAutoFocus={true} sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <ModalDiv>
                <Header>
                    Got a minute?
                    <CircleX onClick={handleModalClose}>
                        <XSpan><X /></XSpan>
                    </CircleX>
                </Header>
                <img src={img} alt="Survey Illustration" width="100%" />
                <SubTitle>The Kenzie Academy Product Team would love to hear from you!</SubTitle>
                <BodyText>If you have 3-5 minutes to spare, please follow the link to take a brief survey about your experience using MyKenzie.</BodyText>
                <Button onClick={handleModalClose} href="https://snhu.qualtrics.com/jfe/form/SV_39MRE822dyXEfYi " target="_blank" rel="noreferrer noopener" >Take Survey</Button>
            </ModalDiv>
        </Modal>
    )
}

export default SurveyModal

const Header = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 33px;
color: #1D3969;
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
margin-bottom: 25px;
`

const ModalDiv = styled.div`
    box-sizing: border-box;
    width: 412px;
    height: 552px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    position: relative;
    border: 2.5px solid #1D3969;
    border-radius: 10px;
`

const CircleX = styled.p`
display: flex;
justify-content: center;
align-items: center;
border: 2px solid rgba(104, 104, 104, 1);
border-radius: 50%;
height: 15px;
width: 15px;
&:hover: {
    background-color: rgb(240,240,240);
    cursor: pointer;
}
margin: 0px;
`
const XSpan = styled.span`
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;

`

const SubTitle = styled.span`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 30px;
color: #414042;
margin-top: 20px;
`

const BodyText = styled.span`
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
color: #414042;
margin-top:10px;
`

const Button = styled.a`
width: 362px;
height: 42px;
background: #1D3969;
border-radius: 50px;
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 22px;
display: flex;
align-items: center;
justify-content: center;
color: #FFFFFF;
&:hover{
    box-shadow: 2px 3px 6px #606060;
};
margin-top: auto;
`